import { hasValue } from "@/utils";
import { DataRepository, DataRepositoryField, ImportConfiguration } from "@/generated/client";
import { ColumnToRepositoryFieldMapping } from ".";
import { UploadedFileField } from "../services";
import { DUPLICATE_ERROR } from "./constants";

export const createInitialMapping: (
    dataRepository: DataRepository,
    importConfiguration: ImportConfiguration,
    uploadedFileFields: UploadedFileField[]
) => ColumnToRepositoryFieldMapping[] = (dataRepository, importConfiguration, uploadedFileFields) => {
    const takenRepositoryFieldNames = new Set<string>([]);

    const mappings = uploadedFileFields.map((field, i) => {
        const repositoryField = dataRepository.fields.find(
            (dataRepositoryField) => dataRepositoryField.name === field.fieldKey
        );
        if (takenRepositoryFieldNames.has(field.fieldKey)) {
            return {
                frontendId: i,
                importConfigurationId: importConfiguration.id,
                fileColumnName: field.fieldKey,
                dataRepositoryField: repositoryField,
                included: true,
                error: DUPLICATE_ERROR as typeof DUPLICATE_ERROR,
            };
        }
        takenRepositoryFieldNames.add(field.fieldKey);
        return {
            frontendId: i,
            importConfigurationId: importConfiguration.id,
            fileColumnName: field.fieldKey,
            dataRepositoryField: repositoryField,
            included: true,
            error: undefined,
        };
    });
    return mappings;
};

export const matchNewlyCreatedFieldsAndColumns = (
    mappings: ColumnToRepositoryFieldMapping[],
    createdFields: DataRepositoryField[]
) => {
    return mappings.map((mapping) => {
        if (!mapping.dataRepositoryField) {
            return { ...mapping, dataRepositoryField: createdFields.find((f) => f.name === mapping.fileColumnName) };
        }
        return mapping;
    });
};

export const fromMappingToQueryInput = (mappings: ColumnToRepositoryFieldMapping[]) => {
    return mappings
        .map((mapping) => {
            if (mapping.dataRepositoryField && mapping.included && !mapping.error)
                return {
                    fileFieldKey: mapping.fileColumnName,
                    dataRepositoryFieldId: mapping.dataRepositoryField.id,
                };
            return undefined;
        })
        .filter(hasValue);
};

export const getFileType = (fileName: string): string | null => {
    const lastDotIndex = fileName.lastIndexOf(".");

    if (lastDotIndex === -1 || lastDotIndex === fileName.length - 1) {
        return null;
    }

    return fileName.slice(lastDotIndex + 1).toLowerCase();
};
