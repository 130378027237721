import { Checkbox, MenuItem, Select, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { fm } from "@/contexts/IntlContext";
import { DataRepositoryField } from "@/generated/client";
import React from "react";
import { UploadedFileField } from "../../services";
import { ColumnToRepositoryFieldMapping } from "../index";
import { SAMPLE_COUNT } from "../interfaces";
import MappingStatusChip from "./MappingStatusChip";
import messages from "./messages";

interface Props {
    mapping: ColumnToRepositoryFieldMapping;
    dataRepositoryFields: DataRepositoryField[];
    mappingDispatch: (mapping: ColumnToRepositoryFieldMapping) => void;
    columnSamples?: UploadedFileField;
}

export const MappingTableRow: React.FC<Props> = ({ mapping, mappingDispatch, dataRepositoryFields, columnSamples }) => {
    /**
     * The MappingTableRow renders each row of the mapping table. The parent component manages the state.
     */

    const uniqueSamples = [...new Set(columnSamples?.samples || [])];
    const baseSamples = uniqueSamples.slice(0, SAMPLE_COUNT);
    const tooltipSamples = uniqueSamples.slice(SAMPLE_COUNT, 10).length
        ? uniqueSamples.slice(SAMPLE_COUNT, 10)
        : [fm(messages.tooltipSamples).toString()];

    const newField = {
        id: "new",
        name: fm(messages.newField, { name: mapping.fileColumnName }).toString(),
    };

    return (
        <TableRow>
            <TableCell>
                <Checkbox
                    size="small"
                    style={{ padding: 0 }}
                    name="deleteAllImports"
                    checked={mapping.included}
                    onChange={(_) => {
                        mappingDispatch({ ...mapping, included: !mapping.included });
                    }}
                />
            </TableCell>
            <TableCell>
                <Typography variant="body2">{mapping.fileColumnName}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2">
                    <Select
                        disabled={!mapping.included}
                        size="small"
                        sx={{ width: "200px", padding: 0, margin: 0 }}
                        label={fm(messages.selectMapping)}
                        name=""
                        value={mapping?.dataRepositoryField?.id ?? newField.id}
                        onChange={(e) => {
                            /** If `New` option is chosen selectedField = undefined */
                            const selectedField = dataRepositoryFields.find((field) => field.id === e.target.value);
                            mappingDispatch({ ...mapping, dataRepositoryField: selectedField });
                        }}
                    >
                        {[newField, ...dataRepositoryFields].map((field) => {
                            return (
                                <MenuItem key={field.id} value={field.id}>
                                    {field.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Typography>
            </TableCell>
            <TableCell>
                <Tooltip title={tooltipSamples.join(" | ")}>
                    <Typography variant="body2">{baseSamples.join(" | ")}</Typography>
                </Tooltip>
            </TableCell>
            <TableCell>
                <MappingStatusChip mapping={mapping} />
            </TableCell>
        </TableRow>
    );
};
