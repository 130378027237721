import { defineMessages } from "react-intl";

const messages = defineMessages({
    defaultTitle: {
        id: "components.Prompt.defaultTitle",
        defaultMessage: "Are you sure?",
        description: "Default header for prompt",
    },
    defaultDescription: {
        id: "components.Prompt.defaultDescription",
        defaultMessage: "Are you sure that you want to proceed with this action?",
        description: "Default description for prompt",
    },
    acceptButton: {
        id: "components.Prompt.defaultAccept",
        defaultMessage: "Yes",
        description: "Default accept text for prompt",
    },
    declineButton: {
        id: "components.Prompt.defaultDecline",
        defaultMessage: "No",
        description: "Default decline text for prompt",
    },
});

export default messages;
