import { IconButton, Stack, styled } from "@mui/material";

export const TransitionStack = styled(
    Stack,
    {}
)(({ theme }) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
}));

export const ExpandButton = styled(
    IconButton,
    {}
)(({ theme }) => ({
    position: "relative",
    right: "-17px",
    width: "20px",
    height: "20px",
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
        backgroundColor: theme.palette.secondary.light,
    },
}));
