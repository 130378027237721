import { defineMessages } from "react-intl";

const messages = defineMessages({
    dataSources: {
        id: "dataRepositories.menu.dataSources",
        defaultMessage: "Data sources",
    },
});

export default messages;
