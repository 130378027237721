import { UploadFileResponse } from "@/components/ImportConfiguration/services";
import { ImportConfiguration } from "@/generated/client";
import React, { PropsWithChildren, Reducer, useContext, useReducer, useMemo } from "react";

export const MAPPING_STEP = "MAPPING";
export const NOTIFICATION_STEP = "NOTIFICATION";
export const FILE_CONFIGURATION_STEP = "FILE_CONFIGURATION";

type UploadSteps = "MAPPING" | "NOTIFICATION" | "FILE_CONFIGURATION";

export type Warning = { level: "WARNING" | "ERROR"; msg: string };
type State = {
    progress: UploadSteps;
    selectedImportConfiguration: ImportConfiguration | undefined;
    fileToUpload: File | undefined;
    uploadResponse: UploadFileResponse | undefined;
    deleteQueryId: string | undefined;
    warning: Warning | undefined;
};

const defaultState: State = {
    progress: FILE_CONFIGURATION_STEP,
    selectedImportConfiguration: undefined,
    fileToUpload: undefined,
    uploadResponse: undefined,
    deleteQueryId: undefined,
    warning: undefined,
};

type Action =
    | { type: "RESET" }
    | { type: "PROGRESS"; progress: UploadSteps }
    | { type: "SELECTED_IMPORT_CONFIGURATION"; importConfiguration: ImportConfiguration | undefined }
    | { type: "SET_FILE_TO_UPLOAD"; file: File | undefined }
    | { type: "SET_UPLOAD_RESPONSE"; uploadResponse: UploadFileResponse }
    | { type: "SET_DELETE_QUERY_ID"; deleteQueryId: string }
    | { type: "SET_WARNING"; warning: Warning | undefined };
type ContextValue = { uploadState: State; dispatch: React.Dispatch<Action> };
const Context = React.createContext<ContextValue | null>(null);

const reducer: Reducer<State, Action> = (prevState, action) => {
    switch (action.type) {
        case "RESET":
            return defaultState;
        case "PROGRESS":
            return { ...prevState, progress: action.progress };
        case "SELECTED_IMPORT_CONFIGURATION":
            return { ...prevState, selectedImportConfiguration: action.importConfiguration };
        case "SET_FILE_TO_UPLOAD":
            return { ...prevState, fileToUpload: action.file };
        case "SET_UPLOAD_RESPONSE":
            return { ...prevState, uploadResponse: action.uploadResponse };
        case "SET_DELETE_QUERY_ID":
            return { ...prevState, deleteQueryId: action.deleteQueryId };
        case "SET_WARNING":
            return { ...prevState, warning: action.warning };
    }
};

const UploadDataPageContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const value = useMemo(
        () => ({
            uploadState: state,
            dispatch,
        }),
        [state, dispatch]
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useUploadPageContext() {
    const context = useContext(Context);
    if (!context) {
        throw new Error("Cannot use hook 'useUploadPageContext' outside an UploadPageContextProvider");
    }
    return context;
}

export default UploadDataPageContextProvider;
