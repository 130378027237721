import { defineMessages } from "react-intl";

const messages = defineMessages({
    header: {
        id: "startSqlImport.header",
        defaultMessage: "Pull data from SQL table",
    },
});

export default messages;
