import { defineMessages } from "react-intl";

const messages = defineMessages({
    missing: {
        id: "uploadPage.importLogTable.row.missing",
        defaultMessage: "missing",
    },
    missingImportConfig: {
        id: "uploadPage.importLogTable.row.missingImportConfig",
        defaultMessage: "Missing import config",
    },
    importedDescription: {
        id: "uploadPage.importLogTable.row.importedDescription",
        defaultMessage: "Number of new rows imported",
    },
    importIdDescription: {
        id: "uploadPage.importLogTable.row.importIdDescription",
        defaultMessage: "Click to copy",
    },
    integration: {
        id: "uploadPage.importLogTable.row.integration",
        defaultMessage: "Integration",
    },
});

export default messages;
