import { defineMessages } from "react-intl";

const messages = defineMessages({
    repositoryPageHeader: {
        id: "uploadPage.dataRepositoryPage.Header",
        defaultMessage: "Data Source",
    },
    repositoryMenuHeader: {
        id: "uploadPage.dataRepositoryPage.repositoryMenuHeader",
        defaultMessage: "Data sources",
    },
    dataFields: {
        id: "uploadPage.dataRepositoryPage.dataFields",
        defaultMessage: "DATA FIELDS",
    },
    editConfiguration: {
        id: "uploadPage.dataRepositoryPage.editConfiguration",
        defaultMessage: "Edit Configuration",
    },
    searchDataFields: {
        id: "uploadPage.dataRepositoryPage.searchDataFields",
        defaultMessage: "Search data fields",
    },
    dataTab: {
        id: "uploadPage.dataRepositoryPage.dataTab",
        defaultMessage: "Data preview",
    },
    importTab: {
        id: "uploadPage.dataRepositoryPage.importTab",
        defaultMessage: "Import log",
    },
    importConfigurations: {
        id: "uploadPage.dataRepositoryPage.importConfigurations",
        defaultMessage: "Import configurations",
    },
    settingsTab: {
        id: "uploadPage.dataRepositoryPage.settingsTab",
        defaultMessage: "Settings",
    },
    uniqueCount: {
        id: "uploadPage.dataRepositoryPage.uniqueCount",
        defaultMessage: "UNIQUE VALUES",
    },
    dataCompleteness: {
        id: "uploadPage.dataRepositoryPage.blankValues",
        defaultMessage: "DATA COMPLETENESS",
    },
    examples: {
        id: "uploadPage.dataRepositoryPage.connectionTab",
        defaultMessage: "EXAMPLES",
    },
    addData: {
        id: "uploadPage.dataRepositoryPage.addData",
        defaultMessage: "Add data",
    },
    noData: {
        id: "uploadPage.dataRepositoryPage.noData",
        defaultMessage: "This source is empty.",
    },
    collection: {
        id: "uploadPage.dataRepositoryPageMenu.collection",
        defaultMessage: "New folder",
    },
    repository: {
        id: "uploadPage.dataRepositoryPageMenu.repository",
        defaultMessage: "New data source",
    },
    others: {
        id: "uploadPage.dataRepositoryPageMenu.others",
        defaultMessage: "Others",
    },
    manageDuplicates: {
        id: "uploadPage.dataRepositoryPage.manageDuplicates",
        defaultMessage: "Manage duplicates",
    },
    noRepositories: {
        id: "uploadPage.dataRepositoryPage.norepositories",
        defaultMessage: "You have no repositories, create one with the plus button on the left hand menu",
    },
    createRepository: {
        id: "uploadPage.dataRepositoryPage.createRepository",
        defaultMessage: "Create data source",
    },
    createRepositoryCollection: {
        id: "uploadPage.dataRepositoryPage.createRepositoryCollection",
        defaultMessage: "Create collection",
    },
    onboarding: {
        id: "uploadPage.dataRepositoryPage.onboarding",
        defaultMessage: "Onboarding",
    },
    rowCount: {
        id: "uploadPage.dataRepositoryPage.rowCount",
        defaultMessage: "{rowCount} Rows",
    },
    fieldCount: {
        id: "uploadPage.dataRepositoryPage.fieldCount",
        defaultMessage: "{fieldCount} Fields",
    },
    dataCompletenessInfo: {
        id: "uploadPage.dataRepositoryPage.dataCompletenessInfo",
        defaultMessage:
            "Data completeness shows how many entries there are of the given field. A low data completeness indicates that a field has missing data.",
    },
    newRepository: {
        id: "uploadPage.dataRepositoryPageMenu.newRepository",
        defaultMessage: "New source",
    },
    sqlConnect: {
        id: "uploadPage.dataRepositoryPageMenu.sqlConnect",
        defaultMessage: "Connect with an sql database",
    },
});

export default messages;
