import { fm } from "@/contexts/IntlContext";

import { XlsxConfiguration } from ".";
import messages from "./messages";

export function validateXlsxConfig(config: XlsxConfiguration): string | null {
    if (Object.values(config).some((no) => !no && no !== 0)) return fm(messages.requiredFieldsValidation).toString();
    if (config.dataStartIndex <= 0 || config.headerRowIndex <= 0)
        return fm(messages.positiveNumbersOnlyValidation).toString();
    if (config.headerRowIndex >= config.dataStartIndex) return fm(messages.headerBelowDataStartValidation).toString();
    return null;
}
