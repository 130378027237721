import { Button, Card, CardContent, CardHeader, Stack } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { Link } from "react-router-dom";
import messages from "./messages";

export const OnboardingPage = () => {
    return (
        <Card sx={{ height: "calc(100vh - 49px)" }} variant="outlined">
            <CardHeader title={fm(messages.noSourcesPage)} />
            <CardContent>
                <Stack sx={{ minWidth: "75%", minHeigt: "75%" }} gap={1}>
                    <Stack>
                        <Link href="/data/tables/setup" to="/data/tables/setup">
                            <Button size="small">{fm(messages.startOnboarding)}</Button>
                        </Link>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};
