import { fm } from "@/contexts/IntlContext";
import messages from "./messages";

export const HEADER_MESSAGES: { title: string; tooltip?: string }[] = [
    { title: fm(messages.fileName).toString() },
    { title: fm(messages.importedAt).toString() },
    { title: fm(messages.importedBy).toString() },
    { title: fm(messages.rowsUploaded).toString(), tooltip: fm(messages.uploadedDescription).toString() },
    { title: fm(messages.rowsUpdated).toString(), tooltip: fm(messages.updatedDescription).toString() },
    { title: fm(messages.invalidRows).toString(), tooltip: fm(messages.invalidDescription).toString() },
    { title: fm(messages.ignoredRows).toString(), tooltip: fm(messages.existingDescription).toString() },
    { title: fm(messages.importId).toString() },
];
