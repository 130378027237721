import { ButtonGroup, IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import DeleteIcon from "@mui/icons-material/Delete";
import CopyAll from "@mui/icons-material/CopyAll";
import { DataRepository, RepositoryRowDeleteQuery } from "@/generated/client";
import { DisplayQuery } from "./DispalyQuery";
import messages from "./messages";

interface OptionProps {
    query: RepositoryRowDeleteQuery;
    currentRepository: DataRepository;
    handleDeleteQuery: (id: string) => void;
}
export const Option: React.FC<OptionProps> = ({ query, currentRepository, handleDeleteQuery }) => {
    return (
        <Stack direction="row" justifyContent="space-between" sx={{ minWidth: "100%" }}>
            <DisplayQuery query={query} currentRepository={currentRepository} />
            <ButtonGroup orientation="vertical" size="small">
                <Tooltip title={fm(messages.deleteQueryTooltip)}>
                    <IconButton onClick={() => handleDeleteQuery(query.id)} color="error">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={fm(messages.copy)}>
                    <IconButton
                        color="secondary"
                        onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                            navigator.clipboard.writeText(query.id);
                        }}
                    >
                        <CopyAll />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>
        </Stack>
    );
};
