import { UploadedFileField } from "../services";

const ROW_STATUS_TYPES = ["positive", "warning", "pending"] as const;
export type RowStatusTypes = typeof ROW_STATUS_TYPES[number];

export type FileColumn = UploadedFileField & {
    included: boolean;
    rowStatus: RowStatusTypes;
    repositoryField: {
        id: string | undefined;
        name: string;
    };
};

export const SAMPLE_COUNT = 3;
