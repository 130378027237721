import React from "react";
import { Typography, Stack } from "@mui/material";
import { fm } from "@/contexts/IntlContext";
import { DataRepository, RepositoryRowDeleteQuery } from "@/generated/client";
import messages from "../messages";

interface Props {
    query: RepositoryRowDeleteQuery;
    currentRepository: DataRepository;
}
export const DisplayQuery: React.FC<Props> = ({ query, currentRepository }) => {
    if (query.queryType === "RELATIVE_DATE") {
        return (
            <Stack gap={1}>
                <Typography variant="subtitle1">{query.queryType.split("_").join(" ")}</Typography>
                <Typography>{fm(messages.id, { id: query.id })}</Typography>
                <Typography>
                    {fm(messages.relativeDateHelpText, {
                        fieldName: currentRepository.fields.find((f) => f.id === query.dataRepositoryFieldId)?.name,
                        numberOfPeriods: query.numberOfPeriods,
                        periodGranularity: query.periodGranularity?.toLowerCase(),
                    })}
                </Typography>
                <Typography>
                    {fm(messages.dateFormat, {
                        dateFormat: query.dateFormat,
                    })}
                </Typography>
            </Stack>
        );
    }
    if (query.queryType === "FIELD_VALUE" && query.value) {
        return (
            <Stack>
                <Typography variant="overline">{query.queryType.split("_").join(" ")}</Typography>
                <Typography>{fm(messages.id, { id: query.id })}</Typography>
                <Typography>
                    {fm(messages.fieldValueHelpText, {
                        fieldName: currentRepository.fields.find((f) => f.id === query.dataRepositoryFieldId)?.name,
                        value: query.value,
                    })}
                </Typography>
            </Stack>
        );
    }
    return null;
};
