import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import * as Sentry from "@sentry/react";
import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { fm } from "@/contexts/IntlContext";
import { StartSqlImportInput, useStartSqlImportMutation } from "@/generated/client";
import globalMessages from "@/messages";
import React, { useState } from "react";
import messages from "./messages";

export const SqlDialectOptions = ["mysql", "mssql"];

export const StartSqlImport: React.FC<{ onClose: () => void; dataRepositoryId: string }> = ({
    dataRepositoryId,
    onClose,
}) => {
    const [startSql, mutation] = useStartSqlImportMutation();
    const [error, setError] = useState<string>();

    const [input, setInput] = useState<StartSqlImportInput>({
        dataRepositoryId,
        database: "",
        dialect: "mysql",
        host: "",
        password: "",
        tableName: "",
        username: "",
    });

    const handleChange = (key: keyof StartSqlImportInput, value: string) => {
        setInput((prev) => ({ ...prev, [key]: value }));
    };

    const handleSubmit = () => {
        if (Object.values(input).some((v) => v === "")) {
            const missingKeys = Object.entries(input)
                .filter(([, v]) => v === "")
                .map(([k]) => k)
                .join(",");
            setError(`Error: missing values ${missingKeys}`);
            return;
        }
        setError(undefined);
        startSql({
            input,
        })
            .then(() => onClose())
            .catch((e) => {
                Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
            });
    };
    const fields: (keyof StartSqlImportInput)[] = ["host", "database", "tableName", "username", "password"];

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>{fm(messages.header)}</DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    {fields.map((f) => {
                        return (
                            <TextField
                                size="small"
                                label={f}
                                key={f}
                                placeholder={f}
                                onChange={(e) => {
                                    handleChange(f, e.target.value);
                                }}
                            />
                        );
                    })}
                    <Autocomplete
                        value={input.dialect}
                        options={SqlDialectOptions}
                        getOptionLabel={(option) => option}
                        onChange={(_, v) => v && handleChange("dialect", v)}
                        renderInput={(params) => <TextField {...params} label="dialect" />}
                    />
                    <Button onClick={handleSubmit}>
                        {fm(globalMessages.submitButton)}
                        <GraphqlRequestContainer asyncData={mutation.result} />
                    </Button>
                    <Typography color="error">{error}</Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
