import { fm } from "@/contexts/IntlContext";
import React from "react";
import Prompt from "@/components/Prompt";
import { Stringish } from "@/utils";
import messages from "./messages";

interface Props {
    children?: React.ReactNode;
    open: boolean;
    title?: Stringish;
    description?: Stringish;
    isProcessing?: boolean;
    onCancel: () => void;
    onDelete: () => void;
    acceptTestId?: string;
    declineTestId?: string;
}

/**
 * Simple prompt that can be used to present the user with a
 * chance to abort a delete
 */

const DeletePrompt: React.FC<Props> = ({
    open,
    title,
    description,
    isProcessing,
    onDelete,
    onCancel,
    children,
    acceptTestId,
    declineTestId,
}) => {
    return (
        <Prompt
            open={open}
            header={title}
            description={description}
            acceptText={fm(messages.delete).toString()}
            declineText={fm(messages.cancel).toString()}
            isProcessing={isProcessing}
            onAccept={onDelete}
            onClose={onCancel}
            acceptTestId={acceptTestId}
            declineTestId={declineTestId}
        >
            {children}
        </Prompt>
    );
};

export default DeletePrompt;
