import { Alert, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import * as Sentry from "@sentry/react";
import {
    DELIMITER_OPTIONS,
    ENCODING_OPTIONS,
    ESCAPECHAR_OPTIONS,
    QUOTECHAR_OPTIONS,
} from "@/components/ImportConfiguration/CreateImportConfiguationForm/constants";
import { useUploadPageContext } from "@/containers/UploadDataPage/context";
import { fm } from "@/contexts/IntlContext";
import {
    DelimiterType,
    EncodingType,
    EscapeCharacterType,
    QuoteCharacterType,
    useUpdateImportConfigurationMutation,
} from "@/generated/client";
import React, { useState } from "react";
import { validateXlsxConfig } from "./helpers";
import messages from "./messages";

export type XlsxConfiguration = {
    headerRowIndex: number;
    dataStartIndex: number;
    numberOfEndLinesToDrop: number;
};

type CsvConfiguration = {
    delimiter: DelimiterType;
    encoding: EncodingType;
    quoteCharacter: QuoteCharacterType;
    escapeCharacter: EscapeCharacterType;
};

const ImportConfigurationDetail: React.FC = () => {
    const {
        uploadState: { selectedImportConfiguration },
        dispatch,
    } = useUploadPageContext();
    const [csvConfiguration, setCsvConfiguration] = useState<CsvConfiguration>({
        delimiter: selectedImportConfiguration?.csvConfiguration?.delimiter || "COMMA",
        encoding: selectedImportConfiguration?.csvConfiguration?.encoding || "UTF",
        escapeCharacter: selectedImportConfiguration?.csvConfiguration?.escapeCharacter ?? "DOUBLE_QUOTE",
        quoteCharacter: selectedImportConfiguration?.csvConfiguration?.quoteCharacter ?? "DOUBLE_QUOTE_QUOTING",
    });
    const [xlsxConfiguration, setXlsxConfiguration] = useState<XlsxConfiguration>({
        headerRowIndex: selectedImportConfiguration?.xlsxConfiguration?.headerRowIndex ?? 1,
        dataStartIndex: selectedImportConfiguration?.xlsxConfiguration?.dataStartIndex ?? 2,
        numberOfEndLinesToDrop: selectedImportConfiguration?.xlsxConfiguration?.numberOfEndLinesToDrop ?? 0,
    });
    const [error, setError] = useState<string | undefined>(undefined);
    const [updateImportConfiguration] = useUpdateImportConfigurationMutation({
        refetchQueries: ["getAllImportConfigurations"],
    });
    const handleXlsxChange = (id: string, config: XlsxConfiguration) => {
        setError(undefined);
        setXlsxConfiguration(config);
        const updateErrors = validateXlsxConfig(config);
        if (updateErrors) {
            setError(updateErrors);
            return;
        }
        updateImportConfiguration({ input: { id, contentType: "XLSX", xlsxConfiguration: config } })
            .then((res) => {
                res.data &&
                    dispatch({
                        type: "SELECTED_IMPORT_CONFIGURATION",
                        importConfiguration: res.data.updateImportConfiguration.importConfiguration,
                    });
            })
            .catch((e) => {
                Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
            });
    };
    const handleCsvChange = (id: string, config: CsvConfiguration) => {
        setCsvConfiguration(config);
        updateImportConfiguration({ input: { id, contentType: "CSV", csvConfiguration: config } })
            .then((res) => {
                res.data &&
                    dispatch({
                        type: "SELECTED_IMPORT_CONFIGURATION",
                        importConfiguration: res.data.updateImportConfiguration.importConfiguration,
                    });
            })
            .catch((e) => {
                Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
            });
    };
    if (!selectedImportConfiguration) return null;
    return (
        <Stack>
            {selectedImportConfiguration.contentType === "XLSX" && (
                <Stack gap={1}>
                    <TextField
                        defaultValue={xlsxConfiguration.headerRowIndex}
                        label={fm(messages.headerRowIndex)}
                        onChange={(e) =>
                            handleXlsxChange(selectedImportConfiguration.id, {
                                ...xlsxConfiguration,
                                headerRowIndex: Number(e.target.value),
                            })
                        }
                        type="number"
                    />
                    <TextField
                        value={xlsxConfiguration.dataStartIndex}
                        label={fm(messages.dataStartIndex)}
                        type="number"
                        onChange={(e) =>
                            handleXlsxChange(selectedImportConfiguration.id, {
                                ...xlsxConfiguration,
                                dataStartIndex: Number(e.target.value),
                            })
                        }
                    />
                    <TextField
                        value={xlsxConfiguration.numberOfEndLinesToDrop}
                        label={fm(messages.numberOfEndLinesToDrop)}
                        type="number"
                        onChange={(e) =>
                            handleXlsxChange(selectedImportConfiguration.id, {
                                ...xlsxConfiguration,
                                numberOfEndLinesToDrop: Number(e.target.value),
                            })
                        }
                    />
                    {error ? (
                        <Alert severity="error">{error}</Alert>
                    ) : (
                        <Alert severity="info">{fm(messages.sheetMessage)}</Alert>
                    )}
                </Stack>
            )}
            {selectedImportConfiguration.contentType === "CSV" && (
                <Stack gap={1}>
                    <Stack direction="row" gap={2}>
                        <FormControl style={{ minWidth: "20%" }}>
                            <InputLabel id="delimiter-label">{fm(messages.delimiter)}</InputLabel>
                            <Select
                                labelId="delimiter-label"
                                label={fm(messages.delimiter)}
                                onChange={(e) =>
                                    handleCsvChange(selectedImportConfiguration.id, {
                                        ...csvConfiguration,
                                        delimiter: e.target.value as DelimiterType,
                                    })
                                }
                                defaultValue={csvConfiguration.delimiter}
                            >
                                {DELIMITER_OPTIONS.map((delimiter) => (
                                    <MenuItem key={delimiter.value} value={delimiter.value}>
                                        {delimiter.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl style={{ minWidth: "20%" }}>
                            <InputLabel id="encoding-label">{fm(messages.encoding)}</InputLabel>
                            <Select
                                labelId="encoding-label"
                                label={fm(messages.encoding)}
                                onChange={(e) =>
                                    handleCsvChange(selectedImportConfiguration.id, {
                                        ...csvConfiguration,
                                        encoding: e.target.value as EncodingType,
                                    })
                                }
                                defaultValue={csvConfiguration.encoding}
                            >
                                {ENCODING_OPTIONS.map((encoding) => (
                                    <MenuItem key={encoding.value} value={encoding.value}>
                                        {encoding.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack direction="row" gap={2}>
                        <FormControl style={{ minWidth: "20%" }}>
                            <InputLabel id="escapechar-label">{fm(messages.escapechar)}</InputLabel>
                            <Select
                                labelId="escapechar-label"
                                label={fm(messages.escapechar)}
                                onChange={(e: SelectChangeEvent<string>) =>
                                    handleCsvChange(selectedImportConfiguration.id, {
                                        ...csvConfiguration,
                                        escapeCharacter: e.target.value as EscapeCharacterType,
                                    })
                                }
                                defaultValue={csvConfiguration.escapeCharacter}
                            >
                                {ESCAPECHAR_OPTIONS.map((escapeChar) => (
                                    <MenuItem key={escapeChar.value} value={escapeChar.value}>
                                        {escapeChar.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl style={{ minWidth: "20%" }}>
                            <InputLabel id="quotechar-label">{fm(messages.quotechar)}</InputLabel>
                            <Select
                                labelId="quotechar-label"
                                label={fm(messages.quotechar)}
                                onChange={(e: SelectChangeEvent<string>) =>
                                    handleCsvChange(selectedImportConfiguration.id, {
                                        ...csvConfiguration,
                                        quoteCharacter: e.target.value as QuoteCharacterType,
                                    })
                                }
                                defaultValue={csvConfiguration.quoteCharacter}
                            >
                                {QUOTECHAR_OPTIONS.map((quoteChar) => (
                                    <MenuItem key={quoteChar.value} value={quoteChar.value}>
                                        {quoteChar.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};

export default ImportConfigurationDetail;
