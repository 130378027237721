import { Button, Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import { fm } from "@/contexts/IntlContext";
import { DataRepositoryCollection, useDeleteDataRepositoryCollectionMutation } from "@/generated/client";
import globalMessages from "@/messages";
import React from "react";

interface Props {
    collection: DataRepositoryCollection | { name: string };

    setEditType: React.Dispatch<React.SetStateAction<"ADD" | "DELETE" | "EDIT" | undefined>>;
    onUpdate?: () => void;
    onDelete?: () => void;
}

export const DeleteRepositoryCollection: React.FC<Props> = ({ setEditType, onUpdate, onDelete, collection }) => {
    const [deleteCollection] = useDeleteDataRepositoryCollectionMutation({
        refetchQueries: ["getAllDataRepositoryCollections", "getAllDataRepositories"],
    });

    const handleDelete = () => {
        "id" in collection &&
            deleteCollection({ input: { id: collection.id } })
                .then(() => {
                    onDelete?.();
                    onUpdate?.();
                    setEditType(undefined);
                })
                .catch((e) => {
                    Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
                });
    };

    return (
        <Stack direction="row" gap={1}>
            <Button size="small" color="secondary" variant="outlined" onClick={() => setEditType(undefined)}>
                {fm(globalMessages.cancel)}
            </Button>

            <Button size="small" color="error" onClick={handleDelete}>
                {fm(globalMessages.delete)}
            </Button>
        </Stack>
    );
};
