import { Chip, Tooltip } from "@mui/material";
import { fm } from "@/contexts/IntlContext";
import React from "react";
import { ColumnToRepositoryFieldMapping } from "../..";
import { DUPLICATE_ERROR } from "../../constants";
import messages from "../messages";

interface Props {
    mapping: ColumnToRepositoryFieldMapping;
}

const MappingStatusChip: React.FC<Props> = ({ mapping }) => {
    if (!mapping.included) return <Chip variant="status" color="warning" label={fm(messages.ignored)} />;
    if (mapping.error)
        return (
            <Tooltip
                title={mapping.error === DUPLICATE_ERROR ? fm(messages.duplicateError) : fm(messages.existingError)}
            >
                <Chip variant="status" color="error" label={fm(messages.error)} />
            </Tooltip>
        );
    if (mapping.dataRepositoryField) return <Chip variant="status" color="neutral" label={fm(messages.mapped)} />;
    return <Chip variant="status" color="success" label={fm(messages.new)} />;
};

export default MappingStatusChip;
