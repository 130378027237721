import { Skeleton } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React from "react";

interface Props {
    numberOfRows: number;
    numberOfColumns: number;
}
export const LoadingRows: React.FC<Props> = ({ numberOfRows, numberOfColumns }) => (
    <>
        {Array.from({ length: numberOfRows }).map((_, i) => {
            const key = i;
            return (
                <TableRow key={key}>
                    {Array.from({ length: numberOfColumns }).map((__, i2) => {
                        const innerKey = `columns-${i2}${key}`;
                        return (
                            <TableCell key={innerKey} width={100}>
                                <Skeleton />
                            </TableCell>
                        );
                    })}
                </TableRow>
            );
        })}
    </>
);
