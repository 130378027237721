import { defineMessages } from "react-intl";

const messages = defineMessages({
    manageDuplicatesHeader: {
        id: "uploadPage.DataRepositoryOverviewPage.modals.manageDuplicatesHeader",
        defaultMessage: "Duplicate configuration",
    },
    removeDuplicates: {
        id: "uploadPage.DataRepositoryOverviewPage.modals.removeDuplicates",
        defaultMessage: "Remove Duplicates",
    },
    selectConfig: {
        id: "uploadPage.DataRepositoryOverviewPage.modals.select",
        defaultMessage: "Select duplicate identifier configuraiton",
    },
    newConfig: {
        id: "uploadPage.DataRepositoryOverviewPage.modals.newConfig",
        defaultMessage: "New Unique Identifier Configuration",
    },
    configNameHeader: {
        id: "uploadPage.DataRepositoryOverviewPage.modals.configNameHeader",
        defaultMessage: "Configuration name",
    },
    configFields: {
        id: "uploadPage.DataRepositoryOverviewPage.modals.configFields",
        defaultMessage: "Unique identifier field(s)",
    },
    deleteMessage: {
        id: "uploadPage.DataRepositoryOverviewPage.modals.deleteMessage",
        defaultMessage: "Removing unique index.",
    },
    createOrUpdateMessage: {
        id: "uploadPage.DataRepositoryOverviewPage.modals.createOrUpdateMessage",
        defaultMessage:
            "Deleting duplicates and creating unique index. Check notification center to follow the progress.",
    },
});

export default messages;
