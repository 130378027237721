import globalMessages from "@/messages";
import * as Sentry from "@sentry/react";
import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import { fm } from "@/contexts/IntlContext";
import { DataRepository, DataRepositoryCollection, useUpdateDataRepositoryMutation } from "@/generated/client";
import React, { useState } from "react";

interface Props {
    collection: DataRepositoryCollection | { name: string };
    repositories: DataRepository[];
    setEditType: React.Dispatch<React.SetStateAction<"ADD" | "DELETE" | "EDIT" | undefined>>;
    onUpdate?: () => void;
}

export const AddRepositoryToCollection: React.FC<Props> = ({ setEditType, onUpdate, collection, repositories }) => {
    const [selectedRepositories, setSelectedRepositories] = useState<DataRepository[]>();

    const [updateRepository] = useUpdateDataRepositoryMutation({
        refetchQueries: ["getAllDataRepositoryCollections"],
    });

    const handleAddRepositories = () => {
        selectedRepositories &&
            Promise.all(
                selectedRepositories.map((repo) =>
                    updateRepository({
                        input: {
                            id: repo.id,
                            name: repo.name,
                            repositoryCollectionId: "id" in collection ? collection.id : null,
                            autoTriggerPipelines: repo.autoTriggerPipelines,
                        },
                    })
                )
            )
                .then(() => {
                    onUpdate?.();
                    setSelectedRepositories([]);
                    setEditType(undefined);
                })
                .catch((e) => Sentry.captureException(e, { tags: { app: "data-repositories-app" } }));
    };

    return (
        <Stack gap={1}>
            <Autocomplete
                multiple
                value={selectedRepositories?.filter(
                    (repo) =>
                        ("id" in collection && repo.repositoryCollectionId === collection.id) ||
                        (collection.name === "Other" && repo.repositoryCollectionId === "null")
                )}
                options={repositories.filter(
                    (repo) =>
                        ("id" in collection && repo.repositoryCollectionId !== collection.id) ||
                        (collection.name === "Other" && repo.repositoryCollectionId !== "null")
                )}
                getOptionLabel={(option) => option.name}
                onChange={(_, v) => setSelectedRepositories(v)}
                renderInput={(params) => <TextField {...params} label="Select repositories" />}
            />

            <Stack direction="row" gap={1}>
                <Button size="small" color="secondary" variant="outlined" onClick={() => setEditType(undefined)}>
                    {fm(globalMessages.cancel)}
                </Button>

                <Button size="small" color="error" onClick={handleAddRepositories}>
                    {fm(globalMessages.add)}
                </Button>
            </Stack>
        </Stack>
    );
};
