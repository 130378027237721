import { defineMessages } from "react-intl";

const messages = defineMessages({
    general: {
        id: "uploadPage.dataRepositoryPage.settingsPage.general",
        defaultMessage: "General",
    },
    dataRepositoryName: {
        id: "uploadPage.dataRepositoryPage.settingsPage.dataRepositoryName",
        defaultMessage: "Source name",
    },
    editButton: {
        id: "uploadPage.dataRepositoryPage.settingsPage.editButton",
        defaultMessage: "Edit",
    },
    saveChanges: {
        id: "uploadPage.dataRepositoryPage.settingsPage.saveChanges",
        defaultMessage: "Save changes",
    },
    renameButton: {
        id: "uploadPage.dataRepositoryPage.settingsPage.renameButton",
        defaultMessage: "Rename",
    },
    renameButtonTooltip: {
        id: "uploadPage.dataRepositoryPage.settingsPage.renameButtonTooltip",
        defaultMessage: "Click to confirm renaming of source",
    },
    repositorySettingsHeader: {
        id: "uploadPage.dataRepositoryPage.settingsPage.repositorySettingsHeader",
        defaultMessage: "Data Source Settings",
    },
    configureIntegration: {
        id: "uploadPage.dataRepositoryPage.settingsPage.configureIntegration",
        defaultMessage: "Configure integration (Deprecated)",
    },
    configureIntegrationButton: {
        id: "uploadPage.dataRepositoryPage.settingsPage.configureIntegrationButton",
        defaultMessage: "Configure integration",
    },
    integration: {
        id: "uploadPage.dataRepositoryPage.settingsPage.integration",
        defaultMessage: "Configure this data source to recieve data by API.",
    },
    manageDuplicates: {
        id: "uploadPage.dataRepositoryPage.settingsPage.manageDuplicates",
        defaultMessage: "Manage duplicates",
    },
    duplication: {
        id: "uploadPage.dataRepositoryPage.settingsPage.duplication",
        defaultMessage: "Prevent duplicate rows by specifying a set of data fields as unique identifiers, e.g. dbId.",
    },
    deleteConfig: {
        id: "uploadPage.dataRepositoryPage.settingsPage.deleteConfig",
        defaultMessage: "Manage delete queries",
    },
    deleteConfigHeader: {
        id: "uploadPage.dataRepositoryPage.settingsPage.deleteConfigHeader",
        defaultMessage: "Delete confgurations",
    },
    deleteConfigDescription: {
        id: "uploadPage.dataRepositoryPage.settingsPage.deleteConfigDescription",
        defaultMessage:
            "Create a delete configuration you can apply when uploading new data to deal with duplicate rows without a unique identifier.",
    },
    deleteConfigButton: {
        id: "uploadPage.dataRepositoryPage.settingsPage.deleteConfigButton",
        defaultMessage: "Create deletion configuration",
    },
    dangerZone: {
        id: "uploadPage.dataRepositoryPage.settingsPage.dangerZone",
        defaultMessage: "Danger zone",
    },
    deleteText: {
        id: "uploadPage.dataRepositoryPage.settingsPage.deleteText",
        defaultMessage:
            "Deleting the data table is an irreversible action. Data will also be removed from tables using this source as a source.",
    },
    deleteHeader: {
        id: "uploadPage.dataRepositoryPage.settingsPage.deleteHeader",
        defaultMessage: "Delete source",
    },
    deleteDataRepository: {
        id: "uploadPage.dataRepositoryPage.settingsPage.deleteDataRepository",
        defaultMessage: "Delete source",
    },
    deletePromptDescription: {
        id: "uploadPage.dataRepositoryPage.settingsPage.deleteDataRepositoryPromptDescription",
        defaultMessage: "Are you sure you want to delete the data source?",
    },
    toggleAutoPropagateHeader: {
        id: "uploadPage.dataRepositoryPage.settingsPage.toggleAutoPropagateHeader",
        defaultMessage: "Run pipelines automatically on new data",
    },
    toggleAutoPropagateDescription: {
        id: "uploadPage.dataRepositoryPage.settingsPage.toggleAutoPropagateDescription",
        defaultMessage:
            "When enabled, a pipeline will run automatically when data is imported. If you are uploading many files at the same time, it could be advantageous to disable this feature and run them as a single process.",
    },
    sqlSyncHeader: {
        id: "uploadPage.dataRepositoryPage.settingsPage.sqlHeader",
        defaultMessage: "Connect with SQL Server",
    },
    sqlSyncDescription: {
        id: "uploadPage.dataRepositoryPage.settingsPage.sqlSyncDescription",
        defaultMessage: "Connect to a Microsoft or MySQL server and perform a one-time sync with the data source.",
    },
    sqlConnect: {
        id: "uploadPage.dataRepositoryPage.settingsPage.sqlConnect",
        defaultMessage: "Connect",
    },
    getDataSourceId: {
        id: "uploadPage.dataRepositoryPage.settingsPage.getDataSourceId",
        defaultMessage: "Data source ID",
    },
    dataSourceIdDescription: {
        id: "uploadPage.dataRepositoryPage.settingsPage.dataSourceIdDescription",
        defaultMessage: "The unique ID for this data source is {dataSourceId}. Use it for pushing data over API.",
    },
    copyToClipBoard: {
        id: "uploadPage.dataRepositoryPage.settingsPage.copyToClipboard",
        defaultMessage: "Copy to clipboard",
    },
    integrateWithIgnite: {
        id: "uploadPage.dataRepositoryPage.settingsPage.integrateWithIgnite",
        defaultMessage: "API documentation",
    },
    howToIntegrate: {
        id: "uploadPage.dataRepositoryPage.settingsPage.howToIntegrate",
        defaultMessage:
            "Explore our API documentation and learn to integrate with Ignite by pushing data to a data source.",
    },
    goToDocumentation: {
        id: "uploadPage.dataRepositoryPage.settingsPage.goToDocumentation",
        defaultMessage: "Go to API docs",
    },
});

export default messages;
