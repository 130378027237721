import { defineMessages } from "react-intl";

const messages = defineMessages({
    header: {
        id: "uploadPage.UploadDataPage.header",
        defaultMessage: "Upload Data",
    },
    notifications: {
        id: "uploadPage.UploadDataPage.notifications",
        defaultMessage: "Notifications",
    },
    uploadFile: {
        id: "uploadPage.UploadDataPage.uploadFile",
        defaultMessage: "Upload File",
    },
    requestFormat: {
        id: "uploadPage.UploadDataPage.requestFormat",
        defaultMessage: "Configuration",
    },
    mapping: {
        id: "uploadPage.UploadDataPage.mapping",
        defaultMessage: "Map data fields",
    },
    selectTypeStep: {
        id: "uploadPage.UploadDataPage.selectTypeStep",
        defaultMessage: "Select type",
    },
    deleteInRangeStep: {
        id: "uploadPage.UploadDataPage.deleteInRangeStep",
        defaultMessage: "Delete",
    },
    mappingStep: {
        id: "uploadPage.UploadDataPage.mappingStep",
        defaultMessage: "Mapping",
    },
    reviewStep: {
        id: "uploadPage.UploadDataPage.reviewStep",
        defaultMessage: "Review",
    },
    file: {
        id: "uploadPage.UploadDataPage.file",
        defaultMessage: "File",
    },
    push: {
        id: "uploadPage.UploadDataPage.push",
        defaultMessage: "POST (REST API)",
    },
    pushFile: {
        id: "uploadPage.UploadDataPage.pushFile",
        defaultMessage: "FILE BY API",
    },
    sapConnection: {
        id: "uploadPage.UploadDataPage.sapConnection",
        defaultMessage: "SAP Connection",
    },
    noFile: {
        id: "uploadPage.UploadDataPage.noFile",
        defaultMessage: "No file has been uploaded",
    },
    selectSource: {
        id: "uploadPage.UploadDataPage.selectSource",
        defaultMessage: "Select data source",
    },
    uploadReview: {
        id: "uploadPage.UploadDataPage.uploadReview",
        defaultMessage:
            "Done :) Your data will soon be available in your source, check the bell icon in the upper right corner to track progress.",
    },
    filetypeExcel: {
        id: "uploadPage.UploadDataPage.filetypeExcel",
        defaultMessage: "EXCEL/CSV",
    },
});

export default messages;
