import { fm } from "@/contexts/IntlContext";
import globalMessages from "@/messages";

import { Edit, MoreHorizOutlined } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { DataRepository } from "@/generated/client";
import React from "react";
import { RenameDataRepository } from "@/components/RenameDataRepository";

interface Props {
    dataRepository: DataRepository;
}

export const RepositoryActionMenu: React.FC<Props> = ({ dataRepository }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedAction, setSelectedAction] = React.useState<"RENAME">();

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Dialog open={!!selectedAction} onClose={() => setSelectedAction(undefined)}>
                <DialogContent>
                    {selectedAction === "RENAME" && (
                        <RenameDataRepository
                            onUpdate={() => setSelectedAction(undefined)}
                            repository={dataRepository}
                        />
                    )}
                </DialogContent>
            </Dialog>
            <IconButton size="small" color="secondary" onClick={handleClick}>
                <MoreHorizOutlined />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem
                    onClick={() => {
                        setSelectedAction("RENAME");
                    }}
                    sx={{ justifyContent: "space-between" }}
                >
                    <Stack direction="row" width="150px" gap={2}>
                        <Edit />
                        <Typography>{fm(globalMessages.rename)}</Typography>
                    </Stack>
                </MenuItem>
            </Menu>
        </>
    );
};
