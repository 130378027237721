import Delete from "@mui/icons-material/Delete";
import { CircularProgress, Container, Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { fm } from "@/contexts/IntlContext";
import React, { DragEventHandler, useState } from "react";
import messages from "./messages";

interface Props {
    onFileChange: (file?: File) => Promise<void>;
    fileTypes: string[];
    loading?: boolean;
    initialFile?: File;
}

/**
 * A component that allows the user to drag and drop a file onto it.
 * @param onFile A callback that is called when a file is dropped.
 * @param fileTypes An array of file types that are allowed to be dropped.
 * @returns A component that allows the user to drag and drop a file onto it.
 */
export const FileDropZone = ({ initialFile, onFileChange, fileTypes, loading }: Props) => {
    const [file, setFile] = useState<File | undefined>(initialFile);

    const dropHandler: DragEventHandler = (ev) => {
        onFileChange(ev.dataTransfer.files[0]).catch((e) => {
            Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
        });
        setFile(ev.dataTransfer.files[0]);
        ev.preventDefault();
    };

    const dragOverHandler: DragEventHandler = (ev) => {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
    };

    const handleFileChange = (files: FileList | null) => {
        if (files && files.length) {
            setFile(files[0]);
            onFileChange(files[0]).catch((e) => {
                Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
            });
        }
    };
    const handleDelete = () => {
        setFile(undefined);
        onFileChange().catch((e) => {
            Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
        });
    };

    return (
        <Stack justifyContent="center" textAlign="center">
            <Stack
                justifyContent="center"
                textAlign="center"
                sx={{
                    width: "100%",
                    height: "200px",
                    border: "2px dashed",
                    borderRadius: "10px",
                    borderColor: (theme) => theme.palette.grey[500],
                    backgroundColor: (theme) => theme.palette.grey[100],
                    cursor: "pointer",
                }}
                id="drop_zone"
                onDrop={dropHandler}
                onDragOver={dragOverHandler}
                onClick={() => {
                    document.getElementById("fileInputField")?.click();
                }}
            >
                <Typography variant="subtitle1">{fm(messages.prompt)}</Typography>
                <Typography>{fm(messages.browsePrompt)}</Typography>
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <Typography color="tertiary">{`${fileTypes.join(", ")}...`}</Typography>
                <Container sx={{ opacity: 0, cursor: "pointer" }}>
                    <input
                        style={{ cursor: "pointer" }}
                        type="file"
                        id="fileInputField"
                        accept={fileTypes.join(",")}
                        onChange={(ev) => handleFileChange(ev.target.files)}
                    />
                </Container>
            </Stack>
            {file && (
                <Stack direction="row" spacing={2}>
                    <Typography variant="subtitle1">{file.name}</Typography>
                    <Delete sx={{ cursor: "pointer" }} onClick={handleDelete} />
                    {loading && <CircularProgress />}
                </Stack>
            )}
        </Stack>
    );
};
