/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useEffect, useState, useMemo } from "react";
import { useDataManagementProcessEventListener } from "@/contexts/DataManagementProcessContext";
import { useGetDataRepositoryRowCountQuery, useGetDataRepositoryRowsQuery } from "@/generated/client";

type ContextValue = {
    repositoryData: ReturnType<typeof useGetDataRepositoryRowsQuery>["result"];
    repositoryCount: ReturnType<typeof useGetDataRepositoryRowCountQuery>["result"];
    paginationData: { page: number; range: number };
    setPaginationData: React.Dispatch<React.SetStateAction<{ page: number; range: number }>>;
};

const Context = React.createContext<ContextValue | null>(null);

interface Props {
    children: React.ReactNode;
    dataRepositoryId: string;
}

const RepositoryDataContextProvider: React.FC<Props> = ({ children, dataRepositoryId }) => {
    const [repositoryId, setRepositoryId] = useState<string>(dataRepositoryId);
    const [paginationData, setPaginationData] = useState<{ page: number; range: number }>({ page: 0, range: 50 });
    const { result: repositoryData, refetch: refetchRowsResult } = useGetDataRepositoryRowsQuery(
        {
            input: {
                index: paginationData.page * paginationData.range,
                range: paginationData.range,
                request: { dataRepositoryId },
            },
        },
        { fetchPolicy: "no-cache" }
    );
    const { result: repositoryCount, refetch: refetchRowCount } = useGetDataRepositoryRowCountQuery({
        input: { dataRepositoryId },
    });

    useEffect(() => {
        if (dataRepositoryId !== repositoryId) {
            setRepositoryId(dataRepositoryId);
            setPaginationData({ ...paginationData, page: 0 });
        }
    }, [dataRepositoryId, repositoryId, paginationData]);

    useDataManagementProcessEventListener("DataRepositoryDataChange", (event) => {
        if (event.type === "PROCESS_FINISHED" && event?.id === dataRepositoryId) {
            refetchRowsResult();
            refetchRowCount();
        }
    });

    const value = useMemo(
        () => ({
            repositoryData,
            repositoryCount,
            paginationData,
            setPaginationData,
        }),
        [repositoryData, repositoryCount, paginationData, setPaginationData]
    );
    return (
        <Context.Provider value={value}>
            <>{children}</>
        </Context.Provider>
    );
};

const useRepositoryDataContext = () => {
    const ctx = useContext(Context);
    if (!ctx) {
        throw new Error("DataTableContext must be used within a DataTablesProvider");
    }
    return ctx;
};

const useGetRepositoryPagination = () => {
    const { paginationData, setPaginationData } = useRepositoryDataContext();
    return {
        paginationData,
        setPaginationData,
    };
};

const useGetRepositoryDataFromContext = () => {
    const { repositoryData } = useRepositoryDataContext();
    return repositoryData;
};

const useGetRepositoryCountFromContext = () => {
    const { repositoryCount } = useRepositoryDataContext();
    return repositoryCount;
};

export {
    useGetRepositoryDataFromContext,
    useGetRepositoryCountFromContext,
    RepositoryDataContextProvider,
    useRepositoryDataContext,
    useGetRepositoryPagination,
};
