import React, { useState } from "react";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import LoadingButton from "@mui/lab/LoadingButton";
import { Autocomplete, DialogContent, FormHelperText, Stack, TextField } from "@mui/material";
import * as Sentry from "@sentry/react";
import { fm } from "@/contexts/IntlContext";
import { DataRepository, DataRepositoryCollection, useCreateDataRepositoryMutation } from "@/generated/client";
import globalMessages from "@/messages";
import messages from "./messages";

interface Props {
    dataRepositoryCollections: DataRepositoryCollection[];
    onCreate: (dataRepository: DataRepository) => void;
}

const CreateDataRepositoryForm: React.FC<Props> = ({ onCreate, dataRepositoryCollections }) => {
    const [createDataRepository, mutation] = useCreateDataRepositoryMutation();
    const [name, setName] = useState<string>("");
    const [repositoryCollectionId, setRepositoryCollectionId] = useState<string | undefined>(undefined);
    const [error, setError] = useState<{ name?: string } | undefined>();

    const validateString = (input: string) => {
        return input.replace(/\s/g, "");
    };

    const handleSubmit = () => {
        const nameStrippedForWhitespace = validateString(name);

        if (!nameStrippedForWhitespace) {
            setError({
                name: fm(messages.required).toString(),
            });
            return;
        }

        createDataRepository({ input: { name, repositoryCollectionId } })
            .then((response) => {
                if (response.data) {
                    onCreate(response.data.createDataRepository.dataRepository);
                    track("Repositories: Create Data Repository");
                }
            })
            .catch((e) => {
                Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
            });
    };

    return (
        <DialogContent>
            <Stack
                paddingTop={1}
                gap={2}
                sx={{ maxHeight: "80vh", minWidth: "75%" }}
                justifyContent="space-between"
                alignContent="space-between"
            >
                <TextField
                    name="name"
                    label={fm(messages.nameFormInputLabel)}
                    helperText={error?.name ?? undefined}
                    type="text"
                    error={!!error?.name}
                    onChange={(event) => {
                        setName(event.target.value);
                        error && !!validateString(name) && setError(undefined);
                    }}
                />
                <FormHelperText>{fm(messages.nameFormInputHelperText)}</FormHelperText>
                <Autocomplete
                    options={dataRepositoryCollections}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    onChange={(_, value) => setRepositoryCollectionId(value?.id)}
                    renderInput={(params) => <TextField {...params} label={fm(messages.repositoryCollectionSelect)} />}
                />

                <LoadingButton loading={mutation.result.type === "loading"} type="submit" onClick={handleSubmit}>
                    {fm(globalMessages.submitButton)}
                </LoadingButton>
                <GraphqlRequestContainer asyncData={mutation.result} loading={null} />
            </Stack>
        </DialogContent>
    );
};
export default CreateDataRepositoryForm;
