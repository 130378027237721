import { track } from "@ignite-analytics/track";
import { Search } from "@mui/icons-material";
import {
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { testIdPrefix } from "@/containers/DataRepositoryPage/testIdPrefix";
import { BottomButtonRow } from "@/containers/UploadDataPage/components/BottomButtonRow";
import { fm } from "@/contexts/IntlContext";
import { useCurrentUser } from "@/entities/users";
import {
    DataRepository,
    DataRepositoryField,
    ImportConfiguration,
    useAddManyDataRepositoryFieldsMutation,
    useAddManyImportFieldMappingsMutation,
    useStartFileImportByFileIdMutation,
} from "@/generated/client";
import * as globalMessages from "@/messages";
import React, { useMemo, useState } from "react";
import { UploadFileResponse } from "../services";
import { MappingTableRow } from "./MappingTableRow";
import { DUPLICATE_ERROR, NAME_CONFLICT_ERROR } from "./constants";
import {
    createInitialMapping,
    fromMappingToQueryInput,
    getFileType,
    matchNewlyCreatedFieldsAndColumns,
} from "./helpers";
import messages from "./messages";

interface Props {
    dataRepository: DataRepository;
    importConfiguration: ImportConfiguration;
    parseResponse: UploadFileResponse;
    file: File;
    handleBack: () => void;
    onImport?: (notificationId: string) => void;
}

export interface ColumnToRepositoryFieldMapping {
    frontendId: number;
    importConfigurationId: string;
    fileColumnName: string;
    dataRepositoryField?: DataRepositoryField;
    newName?: string;
    included: boolean;
    error?: typeof DUPLICATE_ERROR | typeof NAME_CONFLICT_ERROR;
}
export const ImportFieldMappingTable: React.FC<Props> = ({
    dataRepository,
    importConfiguration,
    parseResponse,
    file,
    handleBack,
    onImport,
}) => {
    const [mappings, setMappings] = useState<ColumnToRepositoryFieldMapping[]>(
        createInitialMapping(dataRepository, importConfiguration, parseResponse.fields)
    );
    const [addManyDataRepositoryFields] = useAddManyDataRepositoryFieldsMutation();
    const [addManyImportFieldMappings] = useAddManyImportFieldMappingsMutation();
    const [searchTerm, setSearchTerm] = useState<string>();
    const [allIncluded, toggleAllIncluded] = useState<boolean>(true);
    const [startFileImport] = useStartFileImportByFileIdMutation();
    const user = useCurrentUser();

    // Require all fields in the unique configuration to be mapped
    const missingUniqueFieldNames = useMemo(() => {
        const uniqueFieldIds = dataRepository.uniqueIdentifierConfiguration?.uniqueIdentifierFields;
        if (!uniqueFieldIds) return [];

        const missingFieldIds = uniqueFieldIds.filter(
            (id) => !mappings.find((mapping) => mapping.dataRepositoryField?.id === id && mapping.included)
        );

        return missingFieldIds.map((id) => dataRepository.fields.find((field) => field.id === id)?.name || "<Error>");
    }, [dataRepository, mappings]);

    const [loading, setLoading] = useState(false);
    const handleConfirm = async () => {
        setLoading(true);
        const fieldsToCreate = mappings
            .filter((mapping) => !mapping.dataRepositoryField && mapping.included && !mapping.error)
            .map((m) => m.fileColumnName);
        // if there are new fields to create create them first
        if (fieldsToCreate.length) {
            const res = await addManyDataRepositoryFields({
                input: { dataRepositoryId: dataRepository.id, fieldNames: fieldsToCreate },
            });
            const createdFields = res.data?.addManyDataRepositoryFields.dataRepository.fields || [];
            const mappingsToCreate = fromMappingToQueryInput(
                matchNewlyCreatedFieldsAndColumns(mappings, createdFields)
            );
            await addManyImportFieldMappings({
                input: {
                    importConfigurationId: importConfiguration.id,
                    fieldMappings: mappingsToCreate,
                },
            });
        } else {
            // If not just create mappings right away
            const mappingsToCreate = fromMappingToQueryInput(mappings);
            await addManyImportFieldMappings({
                input: {
                    importConfigurationId: importConfiguration.id,
                    fieldMappings: mappingsToCreate,
                },
            });
        }
        if (!user || !file) return;
        const res = await startFileImport({
            input: {
                fileId: `${parseResponse.fileId}`,
                fileName: file.name,
                importConfigurationId: importConfiguration.id,
                deleteQueryId: null,
                importedBy: user.id,
            },
        });

        track("Repository: Start Import", { fileType: getFileType(file.name) });
        setLoading(false);
        if (onImport && res.data) onImport(res.data.startFileImportByFileId.notificationId);
    };

    const mappingsReducer = (m: ColumnToRepositoryFieldMapping) => {
        const newMappings = mappings.map((mapping) => {
            if (mapping.frontendId === m.frontendId) {
                if (!m.dataRepositoryField && dataRepository.fields.find((f) => f.name === m.fileColumnName)) {
                    return { ...m, error: NAME_CONFLICT_ERROR as typeof NAME_CONFLICT_ERROR };
                }
                return { ...m, error: undefined };
            }
            if (mapping.dataRepositoryField && mapping.dataRepositoryField.id === m.dataRepositoryField?.id) {
                return { ...mapping, dataRepositoryField: undefined, included: false };
            }
            return mapping;
        });
        setMappings(newMappings);
    };

    return (
        <Stack gap={1}>
            <TextField
                sx={{ minWidth: 220, maxWidth: 220 }}
                fullWidth
                data-testid={`${testIdPrefix}-column-search`}
                placeholder={fm(messages.search).toString()}
                onChange={(e) => {
                    setSearchTerm(e.target.value.toLowerCase());
                }}
                InputProps={{
                    endAdornment: <Search fontSize="small" />,
                }}
            />
            <Stack sx={{ maxHeight: "50vh", overflow: "auto" }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell key="included">
                                <Checkbox
                                    sx={{ padding: 0 }}
                                    checked={allIncluded}
                                    name="include"
                                    onChange={(e) => {
                                        setMappings(
                                            mappings.map((mapping) => ({
                                                ...mapping,
                                                included:
                                                    !searchTerm ||
                                                        mapping.fileColumnName
                                                            .toLowerCase()
                                                            .includes(searchTerm.toLowerCase())
                                                        ? e.target.checked
                                                        : mapping.included,
                                            }))
                                        );
                                        toggleAllIncluded(e.target.checked);
                                    }}
                                />
                            </TableCell>
                            <TableCell key="fileFieldName">
                                <Typography noWrap>{fm(messages.fileFieldName)}</Typography>
                            </TableCell>
                            <TableCell key="repositoryFieldName">
                                <Typography noWrap>{fm(messages.repositoryFieldName)}</Typography>
                            </TableCell>
                            <TableCell key="examples">
                                <Typography noWrap>{fm(messages.examples)}</Typography>
                            </TableCell>
                            <TableCell key="mappingStatus">
                                <Typography noWrap> {fm(messages.mappingStatus)} </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mappings.map((mapping) => {
                            if (!searchTerm || mapping.fileColumnName.toLowerCase().includes(searchTerm.toLowerCase()))
                                return (
                                    <MappingTableRow
                                        key={mapping.frontendId}
                                        mapping={mapping}
                                        dataRepositoryFields={dataRepository.fields}
                                        mappingDispatch={mappingsReducer}
                                        columnSamples={parseResponse.fields.find(
                                            (field) => field.fieldKey === mapping.fileColumnName
                                        )}
                                    />
                                );

                            return null;
                        })}
                    </TableBody>
                </Table>
            </Stack>
            <BottomButtonRow
                nextButtonMessage={fm(globalMessages.default.confirm).toString()}
                backButtonMessage={fm(globalMessages.default.back).toString()}
                handleBack={handleBack}
                handleNext={() => {
                    handleConfirm().catch((e) =>
                        Sentry.captureException(e, { tags: { app: "data-repositories-app" } })
                    );
                }}
                loading={loading}
                nextButtonDisabled={!file || missingUniqueFieldNames.length > 0 || mappings.some((mapping) => mapping.included && mapping.error)}
                nextButtonTooltip={
                    missingUniqueFieldNames.length
                        ? fm(messages.missingRequiredFields, { missingFields: missingUniqueFieldNames.map((field) => field).join(", ")}).toString()
                        : ""
                }
            />
        </Stack>
    );
};
