import { FileCopy } from "@mui/icons-material";
import { Card, CardContent, Chip, IconButton, Stack, Typography } from "@mui/material";
import * as importConfigurationMessages from "@/components/ImportConfiguration/CreateImportConfiguationForm/messages";
import { fm } from "@/contexts/IntlContext";
import { ImportConfiguration } from "@/generated/client";
import React from "react";
import messages from "./messages";

interface Props {
    importConfiguration: ImportConfiguration;
}

const ApiConfiguration: React.FC<Props> = ({ importConfiguration }) => {
    const url = `https://api.igniteprocurement.com/r/${localStorage.tenant}/external/departments/1/data-repositories/${
        importConfiguration.targetDataRepositoryId
    }/${importConfiguration.contentType === "JSON" ? "" : "files/"}`;

    const exampleBody =
        importConfiguration.contentType === "JSON"
            ? JSON.stringify(
                  {
                      importConfigurationId: importConfiguration.id,
                      data: [
                          { supplier: "Ignite Procurement", date: "2023-01-01", value: "100", currency: "USD" },
                          { supplier: "Savings Magazine", date: "2023-01-01", value: "200", currency: "USD" },
                      ],
                  },
                  null,
                  4
              )
            : JSON.stringify({ file: `example_file.${importConfiguration.contentType.toLowerCase()}` }, null, 4);
    return (
        <Stack spacing={1}>
            <Typography variant="h5">{fm(messages.requestUrl)}</Typography>
            <Card>
                <CardContent>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Chip color="warning" label="POST" />
                        <Typography variant="body2">{url}</Typography>
                        <IconButton
                            size="small"
                            onClick={() => {
                                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                navigator.clipboard.writeText(url);
                            }}
                        >
                            <FileCopy />
                        </IconButton>
                    </Stack>
                </CardContent>
            </Card>
            <Typography variant="h5">{fm(messages.exampleRequest)}</Typography>
            <Card>
                <CardContent>
                    <pre>
                        <Typography variant="body2">{exampleBody}</Typography>
                    </pre>
                </CardContent>
            </Card>
            <Stack direction="row" gap={1} alignItems="center">
                <Typography variant="subtitle1">{fm(messages.fileType)}</Typography>
                <Typography variant="body2">{importConfiguration.contentType}</Typography>
            </Stack>
            {importConfiguration.contentType === "CSV" && (
                <Stack>
                    <Stack direction="row" gap={1} alignItems="center">
                        <Typography variant="subtitle1">{fm(importConfigurationMessages.default.delimiter)}</Typography>
                        <Typography variant="body2">{importConfiguration.csvConfiguration?.delimiter}</Typography>
                    </Stack>
                    <Stack direction="row" gap={1} alignItems="center">
                        <Typography variant="subtitle1">{fm(importConfigurationMessages.default.encoding)}</Typography>
                        <Typography variant="body2">{importConfiguration.csvConfiguration?.encoding}</Typography>
                    </Stack>
                    <Stack direction="row" gap={1} alignItems="center">
                        <Typography variant="subtitle1">
                            {fm(importConfigurationMessages.default.escapechar)}
                        </Typography>
                        <Typography variant="body2">{importConfiguration.csvConfiguration?.escapeCharacter}</Typography>
                    </Stack>
                    <Stack direction="row" gap={1} alignItems="center">
                        <Typography variant="subtitle1">{fm(importConfigurationMessages.default.quotechar)}</Typography>
                        <Typography variant="body2">{importConfiguration.csvConfiguration?.quoteCharacter}</Typography>
                    </Stack>
                </Stack>
            )}
            <Stack direction="row" gap={1} alignItems="center">
                <Typography variant="subtitle1">{fm(importConfigurationMessages.default.compression)}</Typography>
                <Typography variant="body2">
                    {importConfiguration.compressionType ?? fm(importConfigurationMessages.default.none)}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default ApiConfiguration;
