import { FeatureToggleContainer } from "@ignite-analytics/feature-toggle";
import { Upload } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { StartSqlImport } from "@/components/StartSqlImport";
import { testIdPrefix } from "@/containers/DataRepositoryPage/testIdPrefix";
import { useDataRepositoryContext } from "@/contexts/DataRepositoryContext";
import { fm } from "@/contexts/IntlContext";
import React, { useState } from "react";
import globalMessages from "@/messages";
import messages from "../../messages";
import RepositoryTableView from "./RepositoryTableView";

interface Props {
    openAddDataModal: () => void;
}
const DataRepositoryDataPreview: React.FC<Props> = ({ openAddDataModal }) => {
    const { selectedDataRepository } = useDataRepositoryContext();
    const [openSqlConnector, setOpenSqlConnector] = useState(false);

    if (selectedDataRepository.fields.length) {
        return (
            <Stack gap={1} paddingTop={1}>
                <RepositoryTableView />
            </Stack>
        );
    }
    return (
        <Stack justifyContent="center" alignItems="center" direction="row" gap={2}>
            <Typography>{fm(messages.noData)}</Typography>
            <Button
                size="small"
                startIcon={<Upload />}
                variant="outlined"
                color="secondary"
                onClick={openAddDataModal}
                data-testid={`${testIdPrefix}-add-data-button`}
            >
                {fm(messages.addData)}
            </Button>
            <FeatureToggleContainer featureKey="integrationSetup">
                <>
                    {fm(globalMessages.or)}
                    <Button onClick={() => setOpenSqlConnector(true)}>{fm(messages.sqlConnect)}</Button>
                    {openSqlConnector && (
                        <StartSqlImport
                            onClose={() => setOpenSqlConnector(false)}
                            dataRepositoryId={selectedDataRepository.id}
                        />
                    )}
                </>
            </FeatureToggleContainer>
        </Stack>
    );
};

export default DataRepositoryDataPreview;
