import { defineMessages } from "react-intl";

const messages = defineMessages({
    toggle: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.toggle",
        defaultMessage: "Delete data rows within a date range before upload?",
    },
    saveQuery: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.saveQuery",
        defaultMessage: "Save config",
    },
    dateField: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.dateField",
        defaultMessage: "What repository field should be used",
    },
    startDate: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.startDate",
        defaultMessage: "Delete from (Inclusive)",
    },
    endDate: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.endDate",
        defaultMessage: "Delete to (Inclusive)",
    },
    dateFormat: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.dateFormat",
        defaultMessage: "What format is your source date field",
    },
    type: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.type",
        defaultMessage: "Type of query",
    },
    static: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.static",
        defaultMessage: "Static dates",
    },
    relative: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.relative",
        defaultMessage: "Relative date",
    },
    period: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.period",
        defaultMessage: "Select time period",
    },
    numberOfPeriods: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.numberOfPeriods",
        defaultMessage: "Input number of periods",
    },
    deleteFromDate: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.deleteFromDate",
        defaultMessage: "Would now delete data from: ",
    },
    notSelected: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.notSelected",
        defaultMessage: "NOT SELECTED",
    },
    valueInput: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.valueInput",
        defaultMessage: "Value to delete",
    },
    byValue: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.byValue",
        defaultMessage: "Delete by value",
    },
    last: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.last",
        defaultMessage: "Last ",
    },
    this: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.this",
        defaultMessage: "This",
    },
    cancel: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.cancel",
        defaultMessage: "Cancel",
    },
    newQuery: {
        id: "uploadPage.dataRepositoryPage.deleteQueryBuilder.newQuery",
        defaultMessage: "New delete query",
    },
});

export default messages;
