/* eslint-disable @typescript-eslint/no-misused-promises */
import { zodResolver } from "@hookform/resolvers/zod";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import * as Sentry from "@sentry/react";
import { FileCopy } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import {
    DELIMITER_OPTIONS,
    ENCODING_OPTIONS,
    ESCAPECHAR_OPTIONS,
    QUOTECHAR_OPTIONS,
    VALID_CONTENT_TYPE_OPTIONS,
} from "@/components/ImportConfiguration/CreateImportConfiguationForm/constants";
import * as importConfigurationMessages from "@/components/ImportConfiguration/CreateImportConfiguationForm/messages";
import { testIdPrefix } from "@/containers/DataRepositoryPage/testIdPrefix";
import { fm } from "@/contexts/IntlContext";
import {
    ContentType,
    DataRepository,
    DelimiterType,
    EncodingType,
    EscapeCharacterType,
    ImportConfiguration,
    QuoteCharacterType,
    useCreateImportConfigurationMutation,
} from "@/generated/client";
import * as globalMessages from "@/messages";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as z from "zod";
import ApiConfiguration from "./ApiConfiguration";
import messages from "./messages";

type CONFIGURATION_TYPE = "PUSH_ENDPOINT" | "PUSH_FILE_CONFIGURATION";
type FormType = {
    contentType: ContentType;
    delimiter: DelimiterType;
    encoding: EncodingType;
    escapeCharacter: EscapeCharacterType;
    quoteCharacter: QuoteCharacterType;
    compressed: boolean;
};
interface Props {
    open: boolean;
    onClose: () => void;
    dataRepository: DataRepository;
    existingApiConfiguration?: ImportConfiguration;
    onCreate: () => void;
}
const ConfigureIntegrationModal: React.FC<Props> = ({
    open,
    onClose,
    dataRepository,
    existingApiConfiguration,
    onCreate,
}) => {
    const [createImportConfiguration, { result: creationResult }] = useCreateImportConfigurationMutation();
    const [configurationType, setConfigurationType] = useState<CONFIGURATION_TYPE>("PUSH_ENDPOINT");
    const [contentType, setContentType] = useState<ContentType>("NJSON");
    const handleGenerateJSON = () => {
        createImportConfiguration({
            input: {
                name: "PUSH",
                importType: "PUSH_ENDPOINT",
                contentType: "JSON",
                targetDataRepository: dataRepository.id,
            },
        })
            .then(onCreate)
            .catch((e) => {
                Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
            });
    };
    const handleGenerate = ({ delimiter, encoding, quoteCharacter, escapeCharacter, compressed }: FormType) => {
        createImportConfiguration({
            input: {
                name: "PUSH",
                importType: "PUSH_ENDPOINT",
                contentType,
                targetDataRepository: dataRepository.id,
                csvConfiguration:
                    contentType === "CSV" ? { delimiter, encoding, quoteCharacter, escapeCharacter } : null,
                xlsxConfiguration:
                    contentType === "XLSX" ? { headerRowIndex: 1, dataStartIndex: 2, numberOfEndLinesToDrop: 0 } : null,
                compressionType: compressed ? "GZIP" : null,
            },
        })
            .then(onCreate)
            .catch((e) => {
                Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
            });
    };

    const schema = z.object({
        contentType: z.string(),
        delimiter: z.string().optional(),
        encoding: z.string().optional(),
        escapeCharacter: z.string().optional(),
        quoteCharacter: z.string().optional(),
        compressed: z.boolean(),
    });

    const { control, handleSubmit } = useForm<FormType>({
        defaultValues: {
            contentType: "NJSON",
            delimiter: "SEMICOLON",
            encoding: "UTF",
            escapeCharacter: "DOUBLE_QUOTE",
            quoteCharacter: "DOUBLE_QUOTE_QUOTING",
            compressed: false,
        },
        resolver: zodResolver(schema),
    });
    if (existingApiConfiguration) {
        return (
            <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
                <DialogTitle>{fm(messages.configureIntegration)}</DialogTitle>
                <DialogContent>
                    <GraphqlRequestContainer
                        asyncData={creationResult}
                        notAsked={
                            <Stack gap={1}>
                                <Typography variant="h5"> {fm(messages.tokenHeader)}</Typography>
                                <Typography>{fm(messages.displayTokenInfo)}</Typography>
                            </Stack>
                        }
                    >
                        {(creationResponse) => (
                            <Stack gap={1}>
                                <Typography variant="h5"> {fm(messages.tokenHeader)}</Typography>
                                <Stack direction="row">
                                    <Typography overflow="scroll">
                                        {`JWT ${creationResponse.integrationToken}` ??
                                            fm(messages.tokenGenerationFailure)}
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                            navigator.clipboard.writeText(`JWT ${creationResponse.integrationToken}`);
                                        }}
                                    >
                                        <FileCopy />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        )}
                    </GraphqlRequestContainer>
                    <ApiConfiguration importConfiguration={existingApiConfiguration} />
                </DialogContent>
            </Dialog>
        );
    }
    return (
        <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
            <DialogTitle>{fm(messages.configureIntegration)}</DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    <Stack direction="row">
                        <RadioGroup
                            aria-labelledby="label-select-import-type"
                            row
                            value={configurationType}
                            onChange={({ target }) => {
                                setConfigurationType(target.value as CONFIGURATION_TYPE);
                            }}
                            name="selectImportTypeRadioGroup"
                        >
                            <FormControlLabel
                                value="PUSH_ENDPOINT"
                                control={<Radio data-testid={`${testIdPrefix}-push-api-button`} />}
                                label={fm(messages.pushJson)}
                            />
                            <FormControlLabel
                                value="PUSH_FILE_CONFIGURATION"
                                control={<Radio data-testid={`${testIdPrefix}-push-file-button`} />}
                                label={fm(messages.pushFile)}
                            />
                        </RadioGroup>
                    </Stack>
                    {configurationType === "PUSH_ENDPOINT" && (
                        <Stack direction="row" gap={1} justifyContent="space-between">
                            <Button size="small" color="secondary" sx={{ minWidth: 70 }} onClick={onClose}>
                                {fm(globalMessages.default.cancel)}
                            </Button>
                            <LoadingButton
                                variant="outlined"
                                size="small"
                                color="secondary"
                                sx={{ minWidth: "120px" }}
                                loading={creationResult.type === "loading"}
                                onClick={handleGenerateJSON}
                                data-testid={`${testIdPrefix}-configure-integration-repository`}
                            >
                                {fm(messages.generateToken)}
                            </LoadingButton>
                        </Stack>
                    )}
                    {configurationType === "PUSH_FILE_CONFIGURATION" && (
                        <Stack gap={1}>
                            <Typography variant="h5"> {fm(messages.helpTextHeader)}</Typography>
                            <Typography variant="body2">{fm(messages.helpText)}</Typography>
                            <form onSubmit={handleSubmit(handleGenerate)}>
                                <Stack gap={1}>
                                    <Controller
                                        name="compressed"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={<Switch checked={field.value} onChange={field.onChange} />}
                                                label={fm(messages.compressed)}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="contentType"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <FormControl>
                                                <InputLabel id="contentLabel">{fm(messages.contentType)}</InputLabel>
                                                <Select
                                                    {...field}
                                                    sx={{ minWidth: "100%" }}
                                                    error={!!fieldState.error}
                                                    labelId="contentLabel"
                                                    label={fm(messages.contentType)}
                                                    onChange={(e) => {
                                                        setContentType(e.target.value as ContentType);
                                                        field.onChange(e);
                                                    }}
                                                >
                                                    {VALID_CONTENT_TYPE_OPTIONS.map((cType) => (
                                                        <MenuItem key={cType.value} value={cType.value}>
                                                            {cType.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>
                                                    <Typography color="red">{fieldState.error?.message}</Typography>
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                    {contentType === "CSV" && (
                                        <>
                                            <Controller
                                                name="delimiter"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <FormControl>
                                                        <InputLabel id="delimiterLable">
                                                            {fm(importConfigurationMessages.default.delimiter)}
                                                        </InputLabel>
                                                        <Select
                                                            {...field}
                                                            error={!!fieldState.error}
                                                            labelId="delimiterLable"
                                                            label={fm(importConfigurationMessages.default.delimiter)}
                                                        >
                                                            {DELIMITER_OPTIONS.map((delimiter) => (
                                                                <MenuItem key={delimiter.value} value={delimiter.value}>
                                                                    {delimiter.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText>
                                                            <Typography color="red">
                                                                {fieldState.error?.message}
                                                            </Typography>
                                                        </FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                            <Controller
                                                name="encoding"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <FormControl>
                                                        <InputLabel id="encodingLabel">
                                                            {fm(importConfigurationMessages.default.encoding)}
                                                        </InputLabel>
                                                        <Select
                                                            {...field}
                                                            error={!!fieldState.error}
                                                            labelId="encodingLabel"
                                                            label={fm(importConfigurationMessages.default.encoding)}
                                                        >
                                                            {ENCODING_OPTIONS.map((encoding) => (
                                                                <MenuItem key={encoding.value} value={encoding.value}>
                                                                    {encoding.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText>
                                                            <Typography color="red">
                                                                {fieldState.error?.message}
                                                            </Typography>
                                                        </FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                            <Controller
                                                name="escapeCharacter"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <FormControl>
                                                        <InputLabel id="escapeCharacterLabel">
                                                            {fm(importConfigurationMessages.default.escapechar)}
                                                        </InputLabel>
                                                        <Select
                                                            {...field}
                                                            error={!!fieldState.error}
                                                            labelId="escapeCharacterLabel"
                                                            label={fm(importConfigurationMessages.default.escapechar)}
                                                        >
                                                            {ESCAPECHAR_OPTIONS.map((escapeChar) => (
                                                                <MenuItem
                                                                    key={escapeChar.value}
                                                                    value={escapeChar.value}
                                                                >
                                                                    {escapeChar.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText>
                                                            <Typography color="red">
                                                                {fieldState.error?.message}
                                                            </Typography>
                                                        </FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                            <Controller
                                                name="quoteCharacter"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <FormControl>
                                                        <InputLabel id="quoteCharacterLabel">
                                                            {fm(importConfigurationMessages.default.quotechar)}
                                                        </InputLabel>
                                                        <Select
                                                            {...field}
                                                            error={!!fieldState.error}
                                                            labelId="quoteCharacterLabel"
                                                            label={fm(importConfigurationMessages.default.quotechar)}
                                                        >
                                                            {QUOTECHAR_OPTIONS.map((quoteChar) => (
                                                                <MenuItem key={quoteChar.value} value={quoteChar.value}>
                                                                    {quoteChar.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText>
                                                            <Typography color="red">
                                                                {fieldState.error?.message}
                                                            </Typography>
                                                        </FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </>
                                    )}
                                </Stack>
                                <Stack direction="row" gap={1} justifyContent="space-between">
                                    <Button size="small" color="secondary" sx={{ minWidth: 70 }} onClick={onClose}>
                                        {fm(globalMessages.default.cancel)}
                                    </Button>
                                    <LoadingButton
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        sx={{ minWidth: "120px" }}
                                        loading={creationResult.type === "loading"}
                                        type="submit"
                                        data-testid={`${testIdPrefix}-configure-integration-repository`}
                                    >
                                        {fm(messages.generateToken)}
                                    </LoadingButton>
                                </Stack>
                            </form>
                        </Stack>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ConfigureIntegrationModal;
