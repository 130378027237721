import { Stack, Button } from "@mui/material";
import { fm } from "@/contexts/IntlContext";
import React from "react";
import messages from "../../../messages";
import { testIdPrefix } from "../../../testIdPrefix";

interface Props {
    onPick: React.Dispatch<React.SetStateAction<false | "REPOSITORY" | "COLLECTION" | "PICK">>;
}

const CreateDataRepositoryOrCollectionModal: React.FC<Props> = ({ onPick }) => {
    return (
        <Stack direction="column" gap={2}>
            <Button
                variant="outlined"
                color="secondary"
                size="medium"
                onClick={() => onPick("REPOSITORY")}
                data-testid={`${testIdPrefix}-pick-repository-button`}
            >
                {fm(messages.repository)}
            </Button>
            <Button
                variant="outlined"
                color="secondary"
                onClick={() => onPick("COLLECTION")}
                data-testid={`${testIdPrefix}-pick-collection-button`}
            >
                {fm(messages.collection)}
            </Button>
        </Stack>
    );
};
export default CreateDataRepositoryOrCollectionModal;
