import { defineMessages } from "react-intl";

const messages = defineMessages({
    maxFileSizeError: {
        id: "fileManager.maxFileSizeError",
        defaultMessage:
            "Maximum file size exceeded. Recieved file of size {fileSize} Mb, maximum allowed is {maxSize} Mb",
    },
    fileUploadError: {
        id: "fileManager.fileUploadError",
        defaultMessage: "Error: Something went wrong.",
    },
    xlsxTipUploadTip: {
        id: "fileManager.xlsxTipUploadTip",
        defaultMessage: "(If you are on a windows computer, make sure the file is not open in Excel)",
    },
});

export default messages;
