import { ContentType, DelimiterType, EncodingType, EscapeCharacterType, QuoteCharacterType } from "@/generated/client";

export const VALID_CONTENT_TYPE_OPTIONS: { label: string; value: ContentType }[] = [
    { label: ".csv", value: "CSV" },
    { label: ".xlsx", value: "XLSX" },
    { label: ".json", value: "JSON" },
    { label: ".json (newline-delimited)", value: "NJSON" },
];
export const DELIMITER_OPTIONS: {
    value: DelimiterType;
    label: string;
}[] = [
    { value: "SEMICOLON", label: ";" },
    { value: "COMMA", label: "," },
    { value: "TAB", label: "TAB" },
];

export const QUOTECHAR_OPTIONS: { value: QuoteCharacterType; label: string }[] = [
    { value: "DOUBLE_QUOTE_QUOTING", label: '"' },
    { value: "SINGLE_QUOTE_QUOTING", label: "'" },
    { value: "DISABLED_QUOTING", label: "disabled" },
];

export const ESCAPECHAR_OPTIONS: { value: EscapeCharacterType; label: string }[] = [
    { value: "DOUBLE_QUOTE", label: '"' },
    { value: "VERTICAL_BAR", label: "|" },
    { value: "BACKSLASH", label: "\\" },
];

export const ENCODING_OPTIONS: {
    label: string;
    value: EncodingType;
}[] = [
    { label: "ISO-8859-1", value: "ISO88591" },
    { label: "UTF-8", value: "UTF" },
];
