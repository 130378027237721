

import * as Apollo from "@apollo/client";
import { defaultCache } from "@ignite-analytics/graphql-utilities";
import {
    
    CreateDataTableDocument,
    CreateDataTableMutation,
    CreateDataTableMutationVariables,
    
    GetDataTableDocument,
    GetDataTableQuery,
    GetDataTableQueryVariables,
    
    GetManyDataTablesDocument,
    GetManyDataTablesQuery,
    GetManyDataTablesQueryVariables,
    
    UpdateDataTableDocument,
    UpdateDataTableMutation,
    UpdateDataTableMutationVariables,
    
    DeleteDataTableDocument,
    DeleteDataTableMutation,
    DeleteDataTableMutationVariables,
    DataTable,
    UpdatableDataTableFieldsInput,
} from "./client";
import "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import React, { useContext } from "react";
import { useApolloClient } from "@apollo/client";
import { GraphQLErrors } from "@apollo/client/errors";
import { isNotNullish } from "@ignite-analytics/general-tools";

type RequestState<T, E> =
    | { type: "not-asked" }
    | {
          type: "loading";
          data?: T;
      }
    | { type: "success"; data: T }
    | { type: "error"; error: E };

type ContextValue = {
	createDataTable: (input: CreateDataTableMutationVariables["input"]) => Promise<CreateDataTableMutation["createDataTable"]["entity"]>;
	getDataTable: (id: string) => Promise<GetDataTableQuery["getDataTable"]["entity"]>;
	
    getManyDataTables: () => Promise<GetManyDataTablesQuery["getManyDataTables"]["entities"]>;
    getManyState: RequestState<DataTable[], Error>;
    updateDataTable: (id: string, update: UpdatableDataTableFieldsInput) => Promise<UpdateDataTableMutation["updateDataTable"]["entity"] | GraphQLErrors>;
	deleteDataTable: (id: string) => Promise<DeleteDataTableMutation["deleteDataTable"]["deletedId"] | GraphQLErrors>;
	updateIterator: number;
};
const Context = React.createContext<ContextValue | null>(null);

type Event<Entity> = {
    id: string;
    model: Entity;
    type: "CREATED" | "UPDATED" | "DELETED";
};
type Handler<Entity> = (event: Event<Entity>) => void;
type Props<Entity> = { children: React.ReactNode; useChangeEvent?: (entity: Entity, handler: Handler<Entity>) => void };
export const DataTableContext = <Entity extends string>({ children, useChangeEvent }: Props<Entity>) => {
    const apolloClient = useApolloClient();

    const [updateIterator, setUpdateIterator] = useState(0);
    const [createIterator, setCreateIterator] = useState(0);


    const createDataTable = useCallback(
        async (input: CreateDataTableMutationVariables["input"]) => {
            const result = await apolloClient.mutate<CreateDataTableMutation, CreateDataTableMutationVariables>({
                mutation: CreateDataTableDocument,
                variables: { input },
                fetchPolicy: "no-cache",
            });
            if (result.data) {
                defaultCache.put(result.data.createDataTable.entity);
                setCreateIterator((i) => i + 1);
                return result.data.createDataTable.entity;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            throw new Error("Invalid state, should have error or data");
        },
        [apolloClient]
    );

    const getDataTable = useCallback(
        async (id: string) => {
            const cachedValue = defaultCache.get<DataTable>("DataTable", id);
            if (cachedValue) {
                return cachedValue;
            }
            const result = await apolloClient.query<GetDataTableQuery, GetDataTableQueryVariables>({
                query: GetDataTableDocument,
                variables: { input: { id } },
                fetchPolicy: "no-cache",
            });
            defaultCache.put(result.data.getDataTable.entity);
            return result.data.getDataTable.entity;
        },
        [apolloClient]
    );

    const getManyDataTables = useCallback(async () => {
        const result = await apolloClient.query<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>({
            query: GetManyDataTablesDocument,
            fetchPolicy: "no-cache",
        });
        result.data.getManyDataTables.entities.forEach((entity) => defaultCache.put(entity));
        return result.data.getManyDataTables.entities;
    }, [apolloClient]);
    const [getManyState, setGetManyState] = useState<RequestState<DataTable[], Error>>({ type: "loading" });
    useEffect(() => {
        (async function () {
            setGetManyState({
                type: "loading",
                data: getManyState.type === "success" || getManyState.type === "loading" ? getManyState.data : undefined,
            });
            try {
                const result = await getManyDataTables();
                setGetManyState({ type: "success", data: result });
            } catch (e) {
                setGetManyState({ type: "error", error: e as Error });
            }
        })();
    }, [getManyDataTables, createIterator]);

    const updateDataTable = useCallback(
        async (id: string, update: UpdatableDataTableFieldsInput) => {
            const mask = Object.keys(update);
            const result = await apolloClient.mutate<UpdateDataTableMutation, UpdateDataTableMutationVariables>({
                mutation: UpdateDataTableDocument,
                variables: {
                    input: {
                        id,
                        update,
                        mask,
                    },
                },
            });
            if (result.data) {
                defaultCache.put(result.data.updateDataTable.entity);
                setUpdateIterator((i) => i + 1);
                return result.data.updateDataTable.entity;
            }
            if (result.errors) {
                return result.errors;
            }
            throw new Error("Invalid state, should have error or data");
        },
        [apolloClient]
    );

    const deleteDataTable = useCallback(
        async (id: string) => {
            const result = await apolloClient.mutate<DeleteDataTableMutation, DeleteDataTableMutationVariables>({
                mutation: DeleteDataTableDocument,
                variables: { input: { id } },
            });
            if (result.data) {
                defaultCache.delete("DataTable", result.data.deleteDataTable.deletedId);
                setUpdateIterator((i) => i + 1);
                return result.data.deleteDataTable.deletedId;
            }
            if (result.errors) {
                return result.errors;
            }
            throw new Error("Invalid state, should have error or data");
        },
        [apolloClient]
    );

useChangeEvent?.("DataTable" as Entity, (e: Event<Entity>) => {
    
    if (e.type === "UPDATED") {
        getDataTable(e.id);
        setUpdateIterator((prev) => prev + 1);
    }
    
    
    if (e.type === "CREATED") {
        setCreateIterator((prev) => prev + 1);
    }
    
    if (e.type === "DELETED") {
        defaultCache.delete("DataTable", e.id);
        setUpdateIterator((prev) => prev + 1);
    }
});

    const context: ContextValue = {
        createDataTable,
        updateDataTable,
        getDataTable,
        
        getManyDataTables,
        getManyState,
        deleteDataTable,
        updateIterator,
    };

    return <Context.Provider value={context}>{children}</Context.Provider>;
};

function useDataTableContext() {
    const context = useContext(Context);
    if (!context) {
        throw new Error("useDataTableContext must be wrapped in a DataTableContext");
    }
    return context;
}


export const useCreateDataTableAction = () => {
    const { createDataTable } = useDataTableContext();
    const [state, setState] = useState<RequestState<DataTable, Error>>({ type: "not-asked" });
    const createAction = async (...args: Parameters<typeof createDataTable>) => {
        try {
            setState({ type: "loading" });
            const result = await createDataTable(...args);
            setState({ type: "success", data: result });
            return result;
        } catch (e) {
            setState({ type: "error", error: e as Error });
            throw e;
        }
    };
    return [
        createAction,
        {
            isLoading: state.type === "loading",
            data: state.type === "success" ? state.data : undefined,
            error: state.type === "error" ? state.error : undefined,
        },
    ] as const;
};


export function useDataTable(id: string) {
    const { getDataTable } = useDataTableContext();

    const [state, setState] = useState<RequestState<DataTable, Error>>({ type: "loading" });

    useEffect(() => {
        (async function () {
            setState({
                type: "loading",
                data: state.type === "success" || state.type === "loading" ? state.data : undefined,
            });
            try {
                const result = await getDataTable(id);
                setState({ type: "success", data: result });
            } catch (e) {
                setState({ type: "error", error: e as Error });
            }
        })();
    }, [getDataTable, id]);

    const data =
        (state.type === "success" || state.type === "loading") && state.data
            ? defaultCache.get<DataTable>("DataTable", state.data?.id)
            : undefined;

    return {
        isLoading: state.type === "loading",
        data,
        error: state.type === "error" ? state.error : undefined,
    };
}


export function useManyDataTables() {
    const { getManyState } = useDataTableContext();
    return useMemo(() => ({
        isLoading: getManyState.type === "loading",
        data: getManyState.type === "success" || getManyState.type === "loading"
            ? getManyState.data
            : undefined,
        error: getManyState.type === "error" ? getManyState.error : undefined,
    }), [getManyState]);
}


export const useUpdateDataTableAction = () => {
    const { updateDataTable } = useDataTableContext();
    const [state, setState] = useState<RequestState<boolean, Error>>({ type: "not-asked" });
    const updateAction = async (...args: Parameters<typeof updateDataTable>) => {
        try {
            setState({ type: "loading" });
            const result = await updateDataTable(...args);
            setState({ type: "success", data: true });
            return result;
        } catch (e) {
            setState({ type: "error", error: e as Error });
        }
    };
    return [
        updateAction,
        {
            isLoading: state.type === "loading",
            data: state.type === "success" ? state.data : undefined,
            error: state.type === "error" ? state.error : undefined,
        },
    ] as const;
};


export const useDeleteDataTableAction = () => {
    const { deleteDataTable } = useDataTableContext();
    const [state, setState] = useState<RequestState<string, Error>>({ type: "not-asked" });
    const deleteAction = async (id: string) => {
        try {
            setState({ type: "loading" });
            const result = await deleteDataTable(id);
            if (typeof result === "string") {
                setState({ type: "success", data: result });
                return;
            }
        } catch (e) {
            setState({ type: "error", error: e as Error });
        }
    };
    return [
        deleteAction,
        {
            isLoading: state.type === "loading",
            data: state.type === "success" ? state.data : undefined,
            error: state.type === "error" ? state.error : undefined,
        },
    ] as const;
};

