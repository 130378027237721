import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { NotificationsList } from "@ignite-analytics/notifications";
import React from "react";
import { Stringish } from "@/utils";

export interface Props {
    open: boolean;
    onClose: () => void;
    notificationId: string;
    title: Stringish;
}
export const NotificationModal = ({ open, onClose, notificationId, title }: Props) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title.toString()}</DialogTitle>
            <DialogContent>
                <NotificationsList filter={(notif, _) => notif.id === +notificationId} />
            </DialogContent>
        </Dialog>
    );
};
