import * as Sentry from "@sentry/react";
import React, { PropsWithChildren } from "react";
import FatalErrorFallback from "./FatalErrorFallback";

/**
 * FatalErrorBoundry
 *
 * Use this for avoiding white-screens on the outermost level
 *
 */
const FatalErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <Sentry.ErrorBoundary
            beforeCapture={(scope) => {
                scope.setTag("app", "data-repositories-app");
            }}
            fallback={<FatalErrorFallback />}
        >
            {children}
        </Sentry.ErrorBoundary>
    );
};

export default FatalErrorBoundary;
