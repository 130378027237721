import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import * as Sentry from "@sentry/react";
import { Close, ContentCopy, CopyAll, Delete, IntegrationInstructionsOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Alert,
    Button,
    Card,
    CardContent,
    Divider,
    IconButton,
    Snackbar,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import DeletePrompt from "@/components/Prompt/DeletePrompt";
import { useBasePath } from "@/contexts/BasePathContext";
import { useDataRepositoryContext } from "@/contexts/DataRepositoryContext";
import { useEntityEventListener } from "@/contexts/EntityEventChangeContext";
import { fm } from "@/contexts/IntlContext";
import {
    useDeleteDataRepositoryMutation,
    useGetAllDataRepositoriesQuery,
    useGetApiImportConfigurationQuery,
    useUpdateDataRepositoryMutation,
} from "@/generated/client";
import { formatGrpcErrorMessage } from "@/helpers";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { StartSqlImport } from "@/components/StartSqlImport";
import { testIdPrefix } from "../../testIdPrefix";
import ConfigureIntegrationModal from "../modals/ConfigureIntegrationModal";
import { DeleteByQuery } from "../modals/DeleteByQuery";
import { ManageDuplicatesModal } from "../modals/ManageDuplicatesModal";
import messages from "./messages";

const REPOSITORY_OVERVIEW_PATH = "data-repositories";
const DOCUMENTATION_LINK = "https://data.igniteprocurement.com/docs";

export const SettingsPage: React.FC = () => {
    const { refetch: refetchDataRepositories } = useGetAllDataRepositoriesQuery({});
    const { selectedDataRepository } = useDataRepositoryContext();
    const [deleteRepositoryModal, setDeleteRepositoryModal] = useState(false);
    const [configureIntegrationOpen, setConfigureIntegrationOpen] = useState(false);
    const [editNameState, setEditNameState] = useState(false);
    const [openManageDuplicates, setOpenManageDuplicates] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [updatedName, setUpdatedName] = useState<string>(selectedDataRepository.name);
    const [openSqlConnector, setOpenSqlConnector] = useState(false);
    const [updateDataRepository] = useUpdateDataRepositoryMutation();
    const [deleteDataRepository, { result: deleteResult }] = useDeleteDataRepositoryMutation();
    const { result: apiConfigResult, refetch: refetchApiConfig } = useGetApiImportConfigurationQuery({
        input: { dataRepositoryId: selectedDataRepository.id },
    });
    const browserHistory = useHistory();
    const basePath = useBasePath();

    useEffect(() => {
        setUpdatedName(selectedDataRepository.name);
    }, [selectedDataRepository]);

    useEffect(() => {
        if (deleteResult.type === "error" && !errorMessage) {
            setErrorMessage(formatGrpcErrorMessage(deleteResult.error.message));
            setSnackbarOpen(true);
        }
    }, [deleteResult, errorMessage]);

    useEntityEventListener("DataRepository", (event) => {
        if (event.type === "DELETED") {
            browserHistory.push(`${basePath}${REPOSITORY_OVERVIEW_PATH}/`);
        }
        refetchDataRepositories();
    });

    const handleDelete = () => {
        deleteDataRepository({ input: { id: selectedDataRepository.id } }).catch((e) => {
            Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
        });
        track("Repository settings: Delete Data Repository");
        setDeleteRepositoryModal(false);
    };

    const handleAutoTriggerPipelinesChange = () => {
        updateDataRepository({
            input: {
                id: selectedDataRepository.id,
                name: selectedDataRepository.name,
                repositoryCollectionId: selectedDataRepository.repositoryCollectionId,
                autoTriggerPipelines: !selectedDataRepository.autoTriggerPipelines,
            },
        }).catch((e) => {
            Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
        });
    };

    const handleNameChange = async () => {
        setEditNameState(false);
        if (!updatedName.length || updatedName === selectedDataRepository.name) return;
        await updateDataRepository({
            input: {
                id: selectedDataRepository.id,
                name: updatedName,
                repositoryCollectionId: selectedDataRepository.repositoryCollectionId,
                autoTriggerPipelines: selectedDataRepository.autoTriggerPipelines,
            },
        });
    };

    const handleClose = (_event: SyntheticEvent | UIEvent, reason?: string) => {
        if (reason === "clickaway") return;
        setSnackbarOpen(false);
    };

    const handleCloseIntegration = () => {
        setConfigureIntegrationOpen(false);
    };

    const showApiConfigSetup = useFeatureToggle("deprecated-api-configuration-setup");
    const showIntegrationSetup = useFeatureToggle("integrationSetup");

    return (
        <Stack gap={3}>
            <Stack gap={1}>
                <Typography variant="h6">{fm(messages.general)}</Typography>
                <Card>
                    <CardContent>
                        <Stack divider={<Divider variant="fullWidth" />} gap={1}>
                            <Stack>
                                <Typography variant="subtitle1">{fm(messages.dataRepositoryName)}</Typography>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <TextField
                                        name="name"
                                        value={updatedName}
                                        size="small"
                                        disabled={!editNameState}
                                        onChange={(e) => setUpdatedName(e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        color={editNameState ? "primary" : "secondary"}
                                        size="small"
                                        onClick={() => {
                                            if (editNameState)
                                                handleNameChange().catch((e) => {
                                                    Sentry.captureException(e, {
                                                        tags: { app: "data-repositories-app" },
                                                    });
                                                });
                                            else setEditNameState(true);
                                        }}
                                        data-testid={`${testIdPrefix}-change-table-name-button`}
                                    >
                                        {editNameState ? fm(messages.saveChanges) : fm(messages.editButton)}
                                    </Button>
                                </Stack>
                            </Stack>

                            {showApiConfigSetup && (
                                <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                    <Stack>
                                        <Typography variant="subtitle1">{fm(messages.configureIntegration)}</Typography>
                                        <Typography variant="body2">{fm(messages.integration)}</Typography>
                                    </Stack>
                                    <Button
                                        sx={{ whiteSpace: "nowrap", minWidth: "260px" }}
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<IntegrationInstructionsOutlined />}
                                        onClick={() => setConfigureIntegrationOpen(true)}
                                        data-testid={`${testIdPrefix}-configure-integration-button`}
                                    >
                                        {fm(messages.configureIntegrationButton)}
                                    </Button>
                                </Stack>
                            )}

                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Stack>
                                    <Typography variant="subtitle1">{fm(messages.integrateWithIgnite)}</Typography>
                                    <Typography variant="body2">{fm(messages.howToIntegrate)}</Typography>
                                </Stack>

                                <Button
                                    sx={{ whiteSpace: "nowrap", minWidth: "260px" }}
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    startIcon={<IntegrationInstructionsOutlined />}
                                    onClick={() => {
                                        window.open(DOCUMENTATION_LINK, "_blank", "noopener");
                                        track("Repository settings: Navigate to API documentation");
                                    }}
                                    data-testid={`${testIdPrefix}-copy-data-source-id-button`}
                                >
                                    {fm(messages.goToDocumentation)}
                                </Button>
                            </Stack>

                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Stack>
                                    <Typography variant="subtitle1">{fm(messages.getDataSourceId)}</Typography>
                                    <Typography variant="body2">
                                        {fm(messages.dataSourceIdDescription, {
                                            dataSourceId: selectedDataRepository.id,
                                        })}
                                    </Typography>
                                </Stack>
                                <Button
                                    sx={{ whiteSpace: "nowrap", minWidth: "260px" }}
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    startIcon={<CopyAll />}
                                    onClick={() => {
                                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                        navigator.clipboard.writeText(selectedDataRepository.id);
                                    }}
                                    data-testid={`${testIdPrefix}-copy-data-source-id-button`}
                                >
                                    {fm(messages.copyToClipBoard)}
                                </Button>
                            </Stack>

                            {showIntegrationSetup && (
                                <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                    <Stack>
                                        <Typography variant="subtitle1">{fm(messages.sqlSyncHeader)}</Typography>
                                        <Typography variant="body2">{fm(messages.sqlSyncDescription)}</Typography>
                                    </Stack>
                                    <Button
                                        sx={{ whiteSpace: "nowrap", minWidth: "260px" }}
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<IntegrationInstructionsOutlined />}
                                        onClick={() => setOpenSqlConnector(true)}
                                        data-testid={`${testIdPrefix}-copy-data-source-id-button`}
                                    >
                                        {openSqlConnector && (
                                            <StartSqlImport
                                                onClose={() => setOpenSqlConnector(false)}
                                                dataRepositoryId={selectedDataRepository.id}
                                            />
                                        )}
                                        {fm(messages.sqlConnect)}
                                    </Button>
                                </Stack>
                            )}

                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Stack>
                                    <Typography variant="subtitle1">{fm(messages.manageDuplicates)}</Typography>
                                    <Typography variant="body2">{fm(messages.duplication)}</Typography>
                                </Stack>
                                <Button
                                    sx={{ whiteSpace: "nowrap", minWidth: "260px" }}
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    startIcon={<ContentCopy />}
                                    onClick={() => setOpenManageDuplicates(true)}
                                    data-testid={`${testIdPrefix}-manage-duplicates-button`}
                                >
                                    {fm(messages.manageDuplicates)}
                                </Button>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Stack>
                                    <Typography variant="subtitle1">{fm(messages.deleteConfig)}</Typography>
                                    <Typography variant="body2">{fm(messages.deleteConfigDescription)}</Typography>
                                </Stack>
                                <DeleteByQuery currentRepository={selectedDataRepository} />
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Stack>
                                    <Typography variant="subtitle1">
                                        {fm(messages.toggleAutoPropagateHeader)}
                                    </Typography>
                                    <Typography variant="body2">
                                        {fm(messages.toggleAutoPropagateDescription)}
                                    </Typography>
                                </Stack>
                                <Switch
                                    checked={selectedDataRepository.autoTriggerPipelines}
                                    onChange={handleAutoTriggerPipelinesChange}
                                />
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
            <Stack gap={1}>
                <Typography variant="h6">{fm(messages.dangerZone)}</Typography>
                <Card sx={{ border: "1px solid red" }}>
                    <CardContent>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                            <Stack>
                                <Typography variant="subtitle1">{fm(messages.deleteHeader)}</Typography>
                                <Typography variant="body2">{fm(messages.deleteText)}</Typography>
                            </Stack>
                            <LoadingButton
                                variant="outlined"
                                color="error"
                                startIcon={<Delete />}
                                onClick={() => setDeleteRepositoryModal(true)}
                                loading={deleteResult.type === "loading"}
                                data-testid={`${testIdPrefix}-delete-repository-button`}
                            >
                                {fm(messages.deleteDataRepository)}
                            </LoadingButton>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
            <DeletePrompt
                title={fm(messages.deleteDataRepository)}
                description={fm(messages.deletePromptDescription)}
                onCancel={() => setDeleteRepositoryModal(false)}
                onDelete={handleDelete}
                isProcessing={deleteResult.type === "loading"}
                open={deleteRepositoryModal}
            >
                <GraphqlRequestContainer asyncData={deleteResult} />
            </DeletePrompt>
            <ManageDuplicatesModal
                open={openManageDuplicates}
                onClose={() => setOpenManageDuplicates(false)}
                repository={selectedDataRepository}
            />
            <ConfigureIntegrationModal
                open={configureIntegrationOpen}
                onClose={handleCloseIntegration}
                dataRepository={selectedDataRepository}
                existingApiConfiguration={
                    apiConfigResult.type === "success"
                        ? apiConfigResult.data.importConfiguration ?? undefined
                        : undefined
                }
                onCreate={refetchApiConfig}
            />
            <Snackbar open={snackbarOpen} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                <Alert variant="filled" severity="error">
                    <Stack direction="row" alignItems="center">
                        <Typography>{errorMessage}</Typography>
                        <IconButton size="small" aria-label="close" onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </Alert>
            </Snackbar>
        </Stack>
    );
};
