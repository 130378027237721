import React, { useContext } from "react";

const Context = React.createContext<string | null>(null);

type Props = {
    children: React.ReactNode;
    path: string;
};
const BasePathProvider: React.FC<Props> = ({ path, children }) => {
    return <Context.Provider value={path}>{children}</Context.Provider>;
};

function useBasePath(): string {
    const context = useContext(Context);
    if (!context) {
        throw new Error("useBasePath hook must be used within a BasePathProvider");
    }
    return context;
}

export { BasePathProvider, useBasePath };
