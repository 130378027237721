import { Grid, Skeleton, Stack } from "@mui/material";
import React from "react";
import { LoadingTable } from "../LoadingTable";

export const LoadingPage: React.FC = () => (
    <Stack>
        <Stack>
            {/* The height of the skeletons is based on the theming of the text it is a placeholder for with some adjustments to make it look better */}
            <Skeleton width="20%" height={55} />
            <Stack direction="row" width="100%" gap={1}>
                <Skeleton height={30} width="100%" />
                <Skeleton height={30} width="100%" />
                <Skeleton height={30} width="100%" />
                <Skeleton height={30} width="100%" />
            </Stack>
            <Skeleton height={5} width="100%" />
        </Stack>
        <Grid paddingTop={5}>
            <Skeleton height={15} width="18%" />
            <Skeleton height={30} width="20%" />
            <LoadingTable numberOfColumns={10} numberOfRows={30} />
            <Skeleton height="" width="400px" />
        </Grid>
    </Stack>
);
