import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import { useBasePath } from "@/contexts/BasePathContext";
import { DataRepository, DataTableCollection } from "@/generated/client";

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getUrl } from "../helpers";
import { RepositoryActionMenu } from "./repositoryActionMenu";

function sortReposByName(repos: DataRepository[]): DataRepository[] {
    const copyList = [...repos];
    // eslint-disable-next-line fp/no-mutating-methods
    return copyList.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // Ignore case during comparison
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0; // Names are equal
    });
}

interface Props {
    collection: DataTableCollection | { name: string };
    repositories: DataRepository[];
    currentRepositoryId: string;
    onClick: (repoId: string) => void;
}

export const RepositoryList: React.FC<Props> = ({ collection, repositories, currentRepositoryId, onClick }) => {
    const basePath = useBasePath();
    const location = useLocation();
    const [hoverId, setHoverOverId] = useState<string>();
    const repositoriesToRender =
        "id" in collection
            ? repositories.filter((repo) => repo.repositoryCollectionId === collection.id)
            : repositories.filter((repo) => repo.repositoryCollectionId === "null");

    return (
        <Stack>
            {sortReposByName(repositoriesToRender).map((repository) => {
                return (
                    <div
                        key={`${repository.id}-link`}
                        id={repository.id}
                        onFocus={() => null}
                        onMouseOver={(ev) => {
                            setHoverOverId(ev.currentTarget.id ?? undefined);
                        }}
                        onMouseLeave={() => {
                            setHoverOverId(undefined);
                        }}
                    >
                        <Grid
                            container
                            height={40}
                            direction="row"
                            width="100%"
                            justifyContent="space-between"
                            paddingX={2}
                            sx={(theme) => ({
                                "&:hover": {
                                    backgroundColor: theme.palette.primary.light,
                                },
                            })}
                            alignItems="center"
                        >
                            <Grid item sm={10}>
                                <Link
                                    to={`${basePath}${repository.id}/${getUrl(location.pathname, currentRepositoryId)}`}
                                    style={{ textDecoration: "none" }}
                                    onClick={() => onClick(repository.id)}
                                >
                                    <Stack direction="row" alignItems="center" width="100%">
                                        <Tooltip title={repository.name.length > 20 ? repository.name : undefined}>
                                            <Typography
                                                variant="body2"
                                                noWrap
                                                color={repository.id === currentRepositoryId ? "primary" : "black"}
                                            >
                                                {repository.name}
                                            </Typography>
                                        </Tooltip>
                                    </Stack>
                                </Link>
                            </Grid>
                            <Grid item sm={2}>
                                {hoverId === repository.id && <RepositoryActionMenu dataRepository={repository} />}
                            </Grid>
                        </Grid>
                    </div>
                );
            })}
        </Stack>
    );
};
