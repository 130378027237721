import { defineMessages } from "react-intl";

const messages = defineMessages({
    nameFormInputLabel: {
        id: "uploadPage.createDataRepositoryForm.nameFormInputLabel",
        defaultMessage: "Name",
    },
    nameFormInputHelperText: {
        id: "uploadPage.createDataRepositoryForm.nameFormInputHelperText",
        defaultMessage:
            "This name should reflect what sources of data you intend to populate it with. For example 'Invoices'",
    },
    required: {
        id: "uploadPage.createDataRepositoryForm.missingName",
        defaultMessage: "This field is required",
    },
    repositoryCollectionSelect: {
        id: "uploadPage.createDataRepositoryForm.repositoryCollectionSelect",
        defaultMessage: "Data Source Collection",
    },
    noRepositoryCollectionSelection: {
        id: "uploadPage.createDataRepositoryForm.noRepositoryCollectionSelection",
        defaultMessage: "-",
    },
});

export default messages;
