import { defineMessages } from "react-intl";

const messages = defineMessages({
    formNameInputLabel: {
        id: "uploadPage.updateImportConfiguration.formNameInputLabel",
        defaultMessage: "Name",
    },
    formImportTypeInputLabel: {
        id: "uploadPage.updateImportConfiguration.formImportTypeInputLabel",
        defaultMessage: "CSV or XLSX",
    },
    header: {
        id: "uploadPage.updateImportConfiguration.header",
        defaultMessage: "Advanced options",
    },
    delimiter: {
        id: "uploadPage.updateImportConfiguration.delimiter",
        defaultMessage: "Delimiter",
    },
    quotechar: {
        id: "uploadPage.updateImportConfiguration.quotechar",
        defaultMessage: "Quote character",
    },
    escapechar: {
        id: "uploadPage.updateImportConfiguration.escapechar",
        defaultMessage: "Escape character",
    },
    encoding: {
        id: "uploadPage.updateImportConfiguration.encoding",
        defaultMessage: "Encoding",
    },
    blank: {
        id: "uploadPage.updateImportConfiguration.blank",
        defaultMessage: "(blank)",
    },
    semicolon: {
        id: "uploadPage.updateImportConfiguration.semicolon",
        defaultMessage: "Semicolon",
    },
    comma: {
        id: "uploadPage.updateImportConfiguration.comma",
        defaultMessage: "Comma",
    },
    tab: {
        id: "uploadPage.updateImportConfiguration.tab",
        defaultMessage: "Tab",
    },
    quote: {
        id: "uploadPage.updateImportConfiguration.quote",
        defaultMessage: "Quote",
    },
    disableQuoting: {
        id: "uploadPage.updateImportConfiguration.disableQuoting",
        defaultMessage: "Disable quoting",
    },
    verticalBar: {
        id: "uploadPage.updateImportConfiguration.verticalBar",
        defaultMessage: "Vertical bar",
    },
    backslash: {
        id: "uploadPage.updateImportConfiguration.backslash",
        defaultMessage: "Backslash",
    },
    csv: {
        id: "uploadPage.updateImportConfiguration.csv",
        defaultMessage: "CSV",
    },
    xlsx: {
        id: "uploadPage.updateImportConfiguration.xlsx",
        defaultMessage: "XLSX",
    },
    headerRowIndex: {
        id: "uploadPage.updateImportConfiguration.headerRowIndex",
        defaultMessage: "Header line",
    },
    compression: {
        id: "uploadPage.updateImportConfiguration.compression",
        defaultMessage: "Compression",
    },
    none: {
        id: "uploadPage.updateImportConfiguration.none",
        defaultMessage: "None",
    },

    dataStartIndex: {
        id: "uploadPage.updateImportConfiguration.dataStartIndex",
        defaultMessage: "Include lines from row",
    },
    numberOfEndLinesToDrop: {
        id: "uploadPage.updateImportConfiguration.numberOfEndLinesToDrop",
        defaultMessage: "Drop rows from bottom",
    },
    sheetMessage: {
        id: "uploadPage.updateImportConfiguration.sheetMessage",
        defaultMessage: "Note: Ignite will only read the first sheet of your excel file.",
    },
    positiveNumbersOnlyValidation: {
        id: "uploadPage.updateImportConfiguration.positiveNumbersOnlyValidation",
        defaultMessage: "Only positive numbers are valid for header and include",
    },
    headerBelowDataStartValidation: {
        id: "uploadPage.updateImportConfiguration.headerBelowDataStartValidation",
        defaultMessage: "Include row must be after header",
    },
    requiredFieldsValidation: {
        id: "uploadPage.updateImportConfiguration.requiredFieldsValidation",
        defaultMessage: "All fields are required",
    },
});

export default messages;
