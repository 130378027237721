

import * as Apollo from "@apollo/client";
import { defaultCache } from "@ignite-analytics/graphql-utilities";
import {
    
    CreateUniqueIdentifierConfigurationDocument,
    CreateUniqueIdentifierConfigurationMutation,
    CreateUniqueIdentifierConfigurationMutationVariables,
    
    GetUniqueIdentifierConfigurationDocument,
    GetUniqueIdentifierConfigurationQuery,
    GetUniqueIdentifierConfigurationQueryVariables,
    
    GetManyUniqueIdentifierConfigurationsDocument,
    GetManyUniqueIdentifierConfigurationsQuery,
    GetManyUniqueIdentifierConfigurationsQueryVariables,
    
    UpdateUniqueIdentifierConfigurationDocument,
    UpdateUniqueIdentifierConfigurationMutation,
    UpdateUniqueIdentifierConfigurationMutationVariables,
    
    DeleteUniqueIdentifierConfigurationDocument,
    DeleteUniqueIdentifierConfigurationMutation,
    DeleteUniqueIdentifierConfigurationMutationVariables,
    UniqueIdentifierConfiguration,
    UpdatableUniqueIdentifierConfigurationFieldsInput,
} from "./client";
import "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import React, { useContext } from "react";
import { useApolloClient } from "@apollo/client";
import { GraphQLErrors } from "@apollo/client/errors";
import { isNotNullish } from "@ignite-analytics/general-tools";

type RequestState<T, E> =
    | { type: "not-asked" }
    | {
          type: "loading";
          data?: T;
      }
    | { type: "success"; data: T }
    | { type: "error"; error: E };

type ContextValue = {
	createUniqueIdentifierConfiguration: (input: CreateUniqueIdentifierConfigurationMutationVariables["input"]) => Promise<CreateUniqueIdentifierConfigurationMutation["createUniqueIdentifierConfiguration"]["entity"]>;
	getUniqueIdentifierConfiguration: (id: string) => Promise<GetUniqueIdentifierConfigurationQuery["getUniqueIdentifierConfiguration"]["entity"]>;
	
    getManyUniqueIdentifierConfigurations: () => Promise<GetManyUniqueIdentifierConfigurationsQuery["getManyUniqueIdentifierConfigurations"]["entities"]>;
    getManyState: RequestState<UniqueIdentifierConfiguration[], Error>;
    updateUniqueIdentifierConfiguration: (id: string, update: UpdatableUniqueIdentifierConfigurationFieldsInput) => Promise<UpdateUniqueIdentifierConfigurationMutation["updateUniqueIdentifierConfiguration"]["entity"] | GraphQLErrors>;
	deleteUniqueIdentifierConfiguration: (id: string) => Promise<DeleteUniqueIdentifierConfigurationMutation["deleteUniqueIdentifierConfiguration"]["deletedId"] | GraphQLErrors>;
	updateIterator: number;
};
const Context = React.createContext<ContextValue | null>(null);

type Event<Entity> = {
    id: string;
    model: Entity;
    type: "CREATED" | "UPDATED" | "DELETED";
};
type Handler<Entity> = (event: Event<Entity>) => void;
type Props<Entity> = { children: React.ReactNode; useChangeEvent?: (entity: Entity, handler: Handler<Entity>) => void };
export const UniqueIdentifierConfigurationContext = <Entity extends string>({ children, useChangeEvent }: Props<Entity>) => {
    const apolloClient = useApolloClient();

    const [updateIterator, setUpdateIterator] = useState(0);
    const [createIterator, setCreateIterator] = useState(0);


    const createUniqueIdentifierConfiguration = useCallback(
        async (input: CreateUniqueIdentifierConfigurationMutationVariables["input"]) => {
            const result = await apolloClient.mutate<CreateUniqueIdentifierConfigurationMutation, CreateUniqueIdentifierConfigurationMutationVariables>({
                mutation: CreateUniqueIdentifierConfigurationDocument,
                variables: { input },
                fetchPolicy: "no-cache",
            });
            if (result.data) {
                defaultCache.put(result.data.createUniqueIdentifierConfiguration.entity);
                setCreateIterator((i) => i + 1);
                return result.data.createUniqueIdentifierConfiguration.entity;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            throw new Error("Invalid state, should have error or data");
        },
        [apolloClient]
    );

    const getUniqueIdentifierConfiguration = useCallback(
        async (id: string) => {
            const cachedValue = defaultCache.get<UniqueIdentifierConfiguration>("UniqueIdentifierConfiguration", id);
            if (cachedValue) {
                return cachedValue;
            }
            const result = await apolloClient.query<GetUniqueIdentifierConfigurationQuery, GetUniqueIdentifierConfigurationQueryVariables>({
                query: GetUniqueIdentifierConfigurationDocument,
                variables: { input: { id } },
                fetchPolicy: "no-cache",
            });
            defaultCache.put(result.data.getUniqueIdentifierConfiguration.entity);
            return result.data.getUniqueIdentifierConfiguration.entity;
        },
        [apolloClient]
    );

    const getManyUniqueIdentifierConfigurations = useCallback(async () => {
        const result = await apolloClient.query<GetManyUniqueIdentifierConfigurationsQuery, GetManyUniqueIdentifierConfigurationsQueryVariables>({
            query: GetManyUniqueIdentifierConfigurationsDocument,
            fetchPolicy: "no-cache",
        });
        result.data.getManyUniqueIdentifierConfigurations.entities.forEach((entity) => defaultCache.put(entity));
        return result.data.getManyUniqueIdentifierConfigurations.entities;
    }, [apolloClient]);
    const [getManyState, setGetManyState] = useState<RequestState<UniqueIdentifierConfiguration[], Error>>({ type: "loading" });
    useEffect(() => {
        (async function () {
            setGetManyState({
                type: "loading",
                data: getManyState.type === "success" || getManyState.type === "loading" ? getManyState.data : undefined,
            });
            try {
                const result = await getManyUniqueIdentifierConfigurations();
                setGetManyState({ type: "success", data: result });
            } catch (e) {
                setGetManyState({ type: "error", error: e as Error });
            }
        })();
    }, [getManyUniqueIdentifierConfigurations, createIterator]);

    const updateUniqueIdentifierConfiguration = useCallback(
        async (id: string, update: UpdatableUniqueIdentifierConfigurationFieldsInput) => {
            const mask = Object.keys(update);
            const result = await apolloClient.mutate<UpdateUniqueIdentifierConfigurationMutation, UpdateUniqueIdentifierConfigurationMutationVariables>({
                mutation: UpdateUniqueIdentifierConfigurationDocument,
                variables: {
                    input: {
                        id,
                        update,
                        mask,
                    },
                },
            });
            if (result.data) {
                defaultCache.put(result.data.updateUniqueIdentifierConfiguration.entity);
                setUpdateIterator((i) => i + 1);
                return result.data.updateUniqueIdentifierConfiguration.entity;
            }
            if (result.errors) {
                return result.errors;
            }
            throw new Error("Invalid state, should have error or data");
        },
        [apolloClient]
    );

    const deleteUniqueIdentifierConfiguration = useCallback(
        async (id: string) => {
            const result = await apolloClient.mutate<DeleteUniqueIdentifierConfigurationMutation, DeleteUniqueIdentifierConfigurationMutationVariables>({
                mutation: DeleteUniqueIdentifierConfigurationDocument,
                variables: { input: { id } },
            });
            if (result.data) {
                defaultCache.delete("UniqueIdentifierConfiguration", result.data.deleteUniqueIdentifierConfiguration.deletedId);
                setUpdateIterator((i) => i + 1);
                return result.data.deleteUniqueIdentifierConfiguration.deletedId;
            }
            if (result.errors) {
                return result.errors;
            }
            throw new Error("Invalid state, should have error or data");
        },
        [apolloClient]
    );

useChangeEvent?.("UniqueIdentifierConfiguration" as Entity, (e: Event<Entity>) => {
    
    if (e.type === "UPDATED") {
        getUniqueIdentifierConfiguration(e.id);
        setUpdateIterator((prev) => prev + 1);
    }
    
    
    if (e.type === "CREATED") {
        setCreateIterator((prev) => prev + 1);
    }
    
    if (e.type === "DELETED") {
        defaultCache.delete("UniqueIdentifierConfiguration", e.id);
        setUpdateIterator((prev) => prev + 1);
    }
});

    const context: ContextValue = {
        createUniqueIdentifierConfiguration,
        updateUniqueIdentifierConfiguration,
        getUniqueIdentifierConfiguration,
        
        getManyUniqueIdentifierConfigurations,
        getManyState,
        deleteUniqueIdentifierConfiguration,
        updateIterator,
    };

    return <Context.Provider value={context}>{children}</Context.Provider>;
};

function useUniqueIdentifierConfigurationContext() {
    const context = useContext(Context);
    if (!context) {
        throw new Error("useUniqueIdentifierConfigurationContext must be wrapped in a UniqueIdentifierConfigurationContext");
    }
    return context;
}


export const useCreateUniqueIdentifierConfigurationAction = () => {
    const { createUniqueIdentifierConfiguration } = useUniqueIdentifierConfigurationContext();
    const [state, setState] = useState<RequestState<UniqueIdentifierConfiguration, Error>>({ type: "not-asked" });
    const createAction = async (...args: Parameters<typeof createUniqueIdentifierConfiguration>) => {
        try {
            setState({ type: "loading" });
            const result = await createUniqueIdentifierConfiguration(...args);
            setState({ type: "success", data: result });
            return result;
        } catch (e) {
            setState({ type: "error", error: e as Error });
            throw e;
        }
    };
    return [
        createAction,
        {
            isLoading: state.type === "loading",
            data: state.type === "success" ? state.data : undefined,
            error: state.type === "error" ? state.error : undefined,
        },
    ] as const;
};


export function useUniqueIdentifierConfiguration(id: string) {
    const { getUniqueIdentifierConfiguration } = useUniqueIdentifierConfigurationContext();

    const [state, setState] = useState<RequestState<UniqueIdentifierConfiguration, Error>>({ type: "loading" });

    useEffect(() => {
        (async function () {
            setState({
                type: "loading",
                data: state.type === "success" || state.type === "loading" ? state.data : undefined,
            });
            try {
                const result = await getUniqueIdentifierConfiguration(id);
                setState({ type: "success", data: result });
            } catch (e) {
                setState({ type: "error", error: e as Error });
            }
        })();
    }, [getUniqueIdentifierConfiguration, id]);

    const data =
        (state.type === "success" || state.type === "loading") && state.data
            ? defaultCache.get<UniqueIdentifierConfiguration>("UniqueIdentifierConfiguration", state.data?.id)
            : undefined;

    return {
        isLoading: state.type === "loading",
        data,
        error: state.type === "error" ? state.error : undefined,
    };
}


export function useManyUniqueIdentifierConfigurations() {
    const { getManyState } = useUniqueIdentifierConfigurationContext();
    return useMemo(() => ({
        isLoading: getManyState.type === "loading",
        data: getManyState.type === "success" || getManyState.type === "loading"
            ? getManyState.data
            : undefined,
        error: getManyState.type === "error" ? getManyState.error : undefined,
    }), [getManyState]);
}


export const useUpdateUniqueIdentifierConfigurationAction = () => {
    const { updateUniqueIdentifierConfiguration } = useUniqueIdentifierConfigurationContext();
    const [state, setState] = useState<RequestState<boolean, Error>>({ type: "not-asked" });
    const updateAction = async (...args: Parameters<typeof updateUniqueIdentifierConfiguration>) => {
        try {
            setState({ type: "loading" });
            const result = await updateUniqueIdentifierConfiguration(...args);
            setState({ type: "success", data: true });
            return result;
        } catch (e) {
            setState({ type: "error", error: e as Error });
        }
    };
    return [
        updateAction,
        {
            isLoading: state.type === "loading",
            data: state.type === "success" ? state.data : undefined,
            error: state.type === "error" ? state.error : undefined,
        },
    ] as const;
};


export const useDeleteUniqueIdentifierConfigurationAction = () => {
    const { deleteUniqueIdentifierConfiguration } = useUniqueIdentifierConfigurationContext();
    const [state, setState] = useState<RequestState<string, Error>>({ type: "not-asked" });
    const deleteAction = async (id: string) => {
        try {
            setState({ type: "loading" });
            const result = await deleteUniqueIdentifierConfiguration(id);
            if (typeof result === "string") {
                setState({ type: "success", data: result });
                return;
            }
        } catch (e) {
            setState({ type: "error", error: e as Error });
        }
    };
    return [
        deleteAction,
        {
            isLoading: state.type === "loading",
            data: state.type === "success" ? state.data : undefined,
            error: state.type === "error" ? state.error : undefined,
        },
    ] as const;
};

