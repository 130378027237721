import { defineMessages } from "react-intl";

const messages = defineMessages({
    fileName: {
        id: "uploadPage.importLogTable.fileName",
        defaultMessage: "File name",
    },
    importedAt: {
        id: "uploadPage.importLogTable.importedAt",
        defaultMessage: "Date",
    },
    importId: {
        id: "uploadPage.importLogTable.importId",
        defaultMessage: "Import ID",
    },
    importedBy: {
        id: "uploadPage.importLogTable.importedBy",
        defaultMessage: "User",
    },
    rowsUploaded: {
        id: "uploadPage.importLogTable.rowsUploaded",
        defaultMessage: "Uploaded",
    },
    rowsUpdated: {
        id: "uploadPage.importLogTable.rowsUpdated",
        defaultMessage: "Updated",
    },
    invalidRows: {
        id: "uploadPage.importLogTable.invalid",
        defaultMessage: "Invalid",
    },
    ignoredRows: {
        id: "uploadPage.importLogTable.ignored",
        defaultMessage: "Existing",
    },
    deleteImport: {
        id: "uploadPage.importLogTable.deleteImport",
        defaultMessage: "Delete imports",
    },
    deleteImportsHeader: {
        id: "uploadPage.dataRepositoryPage.importInformation.importLog.deleteImportsHeader",
        defaultMessage: "Delete Data Imports",
    },
    deletePromptDescription: {
        id: "uploadPage.dataRepositoryPage.importInformation.importLog.deletePromptDescription",
        defaultMessage: "Are you sure you want to delete these imports?",
    },
    updatedDescription: {
        id: "uploadPage.dataRepositoryPage.importInformation.importLog.rowsUpdatedDescription",
        defaultMessage: "Number of rows updated with new values",
    },
    uploadedDescription: {
        id: "uploadPage.dataRepositoryPage.importInformation.importLog.rowsUploadedDescription",
        defaultMessage: "Number of uploaded rows",
    },
    existingDescription: {
        id: "uploadPage.dataRepositoryPage.importInformation.importLog.ignoredRowsDescription",
        defaultMessage: "Number of ignored rows due to existance of an identical row",
    },
    invalidDescription: {
        id: "uploadPage.dataRepositoryPage.importInformation.importLog.invalidDescription",
        defaultMessage: "Number of invalid rows due to blank values in a column used as a unique identifier",
    },
    deleteImportNotificationTitle: {
        id: "uploadPage.dataRepositoryPage.importInformation.importLog.deleteImportNotificationTitle",
        defaultMessage: "Deleting import",
    },
    supplierSourceDeletionDisabled: {
        id: "uploadPage.dataRepositoryPage.importInformation.importLog.supplierSourceDeletionDisabled",
        defaultMessage: "Deletion of data used in the supplier table is disabled",
    },
});

export default messages;
