import { defineMessages } from "react-intl";

const messages = defineMessages({
    delete: {
        id: "dataRepositories.global.delete",
        defaultMessage: "Delete",
    },
    or: {
        id: "dataRepositories.global.or",
        defaultMessage: "or",
    },
    save: {
        id: "dataRepositories.global.save",
        defaultMessage: "Save",
    },
    other: {
        id: "dataRepositories.global.other",
        defaultMessage: "Other",
    },
    cancel: {
        id: "dataRepositories.global.cancel",
        defaultMessage: "Cancel",
    },
    rename: {
        id: "dataRepositories.global.rename",
        defaultMessage: "Rename",
    },
    submitButton: {
        id: "dataRepositories.global.submitButton",
        defaultMessage: "Submit",
    },
    add: {
        id: "dataRepositories.global.add",
        defaultMessage: "Add",
    },
    apply: {
        id: "dataRepositories.global.apply",
        defaultMessage: "Apply",
    },
    error: {
        id: "dataRepositories.global.error",
        defaultMessage: "Error",
    },
    edit: {
        id: "dataRepositories.global.edit",
        defaultMessage: "Edit",
    },
    back: {
        id: "dataRepositories.global.back",
        defaultMessage: "Back",
    },
    next: {
        id: "dataRepositories.global.next",
        defaultMessage: "Next",
    },
    confirm: {
        id: "dataRepositories.global.confirm",
        defaultMessage: "Confirm",
    },
    close: {
        id: "dataRepositories.global.close",
        defaultMessage: "Close",
    },
});

export default messages;
