/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

interface WhoAmIResponse {
    error?: {
        id: string;
    };
    identity?: {
        // eslint-disable-next-line camelcase
        metadata_public?: {
            tenant: string;
        };
    };
}

async function totp() {
    const setupURL = "/ory/self-service/login/browser?aal=aal2&refresh=true";
    const setupResponse = await fetch(setupURL, { headers: { accept: "application/json" }, method: "GET" });
    const setupData = await setupResponse.json();

    const code = prompt("Please enter your 2FA code");

    const csrfToken = setupData.ui.nodes[0].attributes.value;
    const { action } = setupData.ui;
    const flowId = action.split("login?flow=").pop();

    const totpURL = `/ory/self-service/login?flow=${flowId}`;

    await fetch(totpURL, {
        method: "POST",
        credentials: "include",
        headers: { accept: "application/json", "Content-Type": "application/json" },
        body: JSON.stringify({
            csrf_token: csrfToken,
            method: "totp",
            totp_code: code,
        }),
    });
}

async function setTenant() {
    const sessionURL = `/ory/sessions/whoami`;
    const sessionResponse = await fetch(sessionURL, { headers: { accept: "application/json" }, method: "GET" });
    const sessionData: WhoAmIResponse = await sessionResponse.json();

    let tenant: string | undefined;
    if (sessionData.error?.id === "session_aal2_required") {
        await totp();
        const sessionResponseAfterTotp = await fetch(sessionURL, {
            headers: { accept: "application/json" },
            method: "GET",
        });
        const sessionDataAfterTotp = await sessionResponseAfterTotp.json();
        tenant = sessionDataAfterTotp.identity?.metadata_public?.tenant;
    } else {
        tenant = sessionData.identity?.metadata_public?.tenant;
    }

    if (!tenant) {
        throw new Error("Could not get tenant");
    }
    localStorage.setItem("tenant", tenant);
    document.cookie = `tenant=${tenant}; path=/`; // notifier needs tenant cookie
}

export async function setupProxyAuth() {
    const setupURL = "/ory/self-service/login/browser";
    const setupResponse = await fetch(setupURL, { headers: { accept: "application/json" }, method: "GET" });
    const setupData = await setupResponse.json();

    if (setupData.error?.id !== "session_already_available") {
        const username = process.env.REACT_APP_DEFAULT_USER ?? prompt("Please enter your username");
        const password = process.env.REACT_APP_DEFAULT_PASSWORD ?? prompt("Please enter your password");

        const csrfNode = setupData.ui.nodes.find((node: any) => node.attributes.name === "csrf_token");
        const csrfToken = csrfNode.attributes.value;
        const { action } = setupData.ui;
        const flowId = action.split("login?flow=").pop();

        const loginURL = `/ory/self-service/login?flow=${flowId}`;

        await fetch(loginURL, {
            method: "POST",
            credentials: "include",
            headers: { accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify({
                csrf_token: csrfToken,
                method: "password",
                identifier: username,
                password,
                password_identifier: username,
            }),
        });
    }
    await setTenant();
}
