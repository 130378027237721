import { defineMessages } from "react-intl";

const messages = defineMessages({
    nameFormInputLabel: {
        id: "uploadPage.createDataRepositoryCollectionForm.nameFormInputLabel",
        defaultMessage: "Name",
    },
    nameFormInputHelperText: {
        id: "uploadPage.createDataRepositoryCollectionForm.nameFormInputHelperText",
        defaultMessage: "This name should reflect what the group of repositories have in common",
    },
    createCollectionHeader: {
        id: "uploadPage.createDataRepositoryCollectionForm.createCollectionHeader",
        defaultMessage: "Create a collection",
    },
    selectRepos: {
        id: "uploadPage.createDataRepositoryCollectionForm.selectRepos",
        defaultMessage: "Select repositories to include",
    },
    required: {
        id: "uploadPage.createDataRepositoryCollectionForm.missingName",
        defaultMessage: "This field is required",
    },
});

export default messages;
