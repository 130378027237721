import ContentCard, { TabLink } from "@/components/ContentCard";
import { useBasePath } from "@/contexts/BasePathContext";
import { useDataRepositoryContext } from "@/contexts/DataRepositoryContext";
import { RepositoryDataContextProvider } from "@/contexts/DataRepositoryRowsContext";
import { fm } from "@/contexts/IntlContext";
import { Upload } from "@mui/icons-material";
import { Button, Dialog, DialogContent, Stack } from "@mui/material";
import React, { useCallback, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { UploadDataPage } from "../../UploadDataPage";
import UploadDataPageContextProvider from "../../UploadDataPage/context";
import DataRepositoryDataPreview from "../Components/DataRepositoryDataPreview";
import ImportLogTable from "../Components/ImportLogPage/ImportLogTable";
import { SettingsPage } from "../Components/SettingsPage";
import messages from "../messages";
import { testIdPrefix } from "../testIdPrefix";

interface Props {
    sideMenuOpen: boolean;
}

const DataRepositoryOverview: React.FC<Props> = ({ sideMenuOpen }) => {
    const basePath = useBasePath();
    const location = useLocation();

    const [addDataModalOpen, setAddDataModalOpen] = useState(false);

    const { selectedDataRepository } = useDataRepositoryContext();
    React.useEffect(() => {
        const data = {
            source: "ignite-breadcrumbs",
            payload: [{ text: fm(messages.repositoryMenuHeader).toString() }, { text: selectedDataRepository.name }],
        };
        window.postMessage(data, window.location.origin);
    }, [selectedDataRepository]);

    const TAB_LINKS: TabLink[] = [
        {
            path: `${basePath}${selectedDataRepository.id}/data-preview/`,
            name: fm(messages.dataTab).toString(),
            dataTestId: `${testIdPrefix}-data-preview-tab`,
            active: location.pathname.includes(`/data-preview/`),
        },
        {
            path: `${basePath}${selectedDataRepository.id}/import-log/`,
            name: fm(messages.importTab).toString(),
            dataTestId: `${testIdPrefix}-import-log-tab`,
            active: location.pathname.includes(`/import-log/`),
        },
        {
            path: `${basePath}${selectedDataRepository.id}/settings/`,
            name: fm(messages.settingsTab).toString(),
            dataTestId: `${testIdPrefix}-settings-tab`,
            active: location.pathname.includes(`/settings/`),
        },
    ];
    const openAddDataModal = useCallback(() => {
        setAddDataModalOpen(true);
    }, [setAddDataModalOpen]);

    return (
        <RepositoryDataContextProvider dataRepositoryId={selectedDataRepository.id}>
            <ContentCard
                sideMenuOpen={sideMenuOpen}
                tabLinks={TAB_LINKS}
                AddDataButton={
                    selectedDataRepository.fields.length ? (
                        <Stack direction="row" alignItems="center">
                            <Button
                                size="small"
                                startIcon={<Upload />}
                                variant="contained"
                                color="secondary"
                                onClick={() => setAddDataModalOpen(true)}
                                data-testid={`${testIdPrefix}-add-data-button`}
                            >
                                {fm(messages.addData)}
                            </Button>
                        </Stack>
                    ) : undefined
                }
            >
                <Switch>
                    <Route path="*/import-log/">
                        <ImportLogTable />
                    </Route>
                    <Route path="*/settings/">
                        <SettingsPage />
                    </Route>
                    <Route path={["*/data-preview/", ""]}>
                        <DataRepositoryDataPreview openAddDataModal={openAddDataModal} />
                    </Route>
                </Switch>
            </ContentCard>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={addDataModalOpen}
                onClose={() => {
                    setAddDataModalOpen(false);
                }}
            >
                <DialogContent>
                    <UploadDataPageContextProvider>
                        <UploadDataPage onClose={() => setAddDataModalOpen(false)} />
                    </UploadDataPageContextProvider>
                </DialogContent>
            </Dialog>
        </RepositoryDataContextProvider>
    );
};

export default DataRepositoryOverview;
