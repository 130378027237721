import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useGetUsersLazyQuery } from "../generated/client";

export function useCurrentUser() {
    const [user, setUser] = useState<{
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        tenant: string;
    } | null>(null);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_ORY_API_URL}/sessions/whoami`, { credentials: "include" })
            .then((res) => res.json())
            .then(
                (j: {
                    identity: {
                        id: string;
                        traits: { email: string; name: { first: string; last: string } };
                        metadata_public: { tenant: string };
                    };
                }) => {
                    setUser({
                        id: j.identity.id,
                        email: j.identity.traits.email,
                        firstName: j.identity.traits.name.first,
                        lastName: j.identity.traits.name.last,
                        tenant: j.identity.metadata_public.tenant,
                    });
                }
            )
            .catch((e) => {
                Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
            });
    }, []);
    return user;
}

export function useAllUsers() {
    const [getUsers] = useGetUsersLazyQuery();
    const [users, setUsers] = useState<{ id: string; email: string; firstName: string; lastName: string }[]>([]);
    useEffect(() => {
        getUsers({ input: { terms: "" } })
            .then((j) => {
                setUsers(j.data?.getUsers.result ?? []);
            })
            .catch((e) => {
                Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
            });
    }, [getUsers]);
    return users;
}
