import { ContentType, DelimiterType, EncodingType, EscapeCharacterType, QuoteCharacterType } from "@/generated/client";

export const getContentTypeFromFile = (file?: File): ContentType => {
    if (file?.name.toLowerCase().endsWith(".csv")) {
        return "CSV";
    }
    if (file?.name.toLowerCase().endsWith(".xlsx")) {
        return "XLSX";
    }
    return "CSV";
};

export type FormValues = {
    name: string;
    contentType: ContentType;
    headerRowIndex: number;
    dataStartIndex: number;
    numberOfEndLinesToDrop: number;
    delimiter: DelimiterType;
    encoding: EncodingType;
    escapeCharacter: EscapeCharacterType;
    quoteCharacter: QuoteCharacterType;
};

export const createMutationInput = (values: FormValues) => {
    switch (values.contentType) {
        case "CSV":
            return {
                name: values.name,
                contentType: values.contentType,
                csvConfiguration: {
                    delimiter: values.delimiter,
                    encoding: values.encoding,
                    escapeCharacter: values.escapeCharacter,
                    quoteCharacter: values.quoteCharacter,
                },
            };
        case "XLSX":
            return {
                name: values.name,
                contentType: values.contentType,
                xlsxConfiguration: {
                    headerRowIndex: values.headerRowIndex,
                    dataStartIndex: values.dataStartIndex,
                    numberOfEndLinesToDrop: values.numberOfEndLinesToDrop,
                },
            };
        case "JSON":
        case "NJSON":
            return {
                name: values.name,
                contentType: values.contentType,
            };
    }
};
