import { FolderLineRound, PlusLineRound } from "@ignite-analytics/icons";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, Menu, MenuItem, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useBasePath } from "@/contexts/BasePathContext";
import { fm } from "@/contexts/IntlContext";
import { DataRepository, DataRepositoryCollection } from "@/generated/client";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Stringish } from "@/utils";
import messages from "../../../messages";
import CreateDataRepositoryCollectionModal from "../CreateCollectionForm";
import CreateDataRepositoryForm from "../CreateDataRepositoryForm";

export const SIDE_MENU_WIDTH = 260;

const ExitRow: React.FC<{
    message: Stringish;
    closer: () => void;
}> = ({ message, closer }) => {
    return (
        <DialogTitle>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5">{message.toString()}</Typography>
                <IconButton onClick={closer}>
                    <CloseIcon />
                </IconButton>
            </Stack>
        </DialogTitle>
    );
};

interface Props {
    dataRepositoryCollections: DataRepositoryCollection[];
    dataRepositories: DataRepository[];
    refetchDataRepositories: () => void;
    refetchDataRepositoryCollections: () => void;
    onCreate: (id: string) => void;
}

const CreateMenu: React.FC<Props> = ({
    dataRepositoryCollections,
    dataRepositories,
    refetchDataRepositories,
    refetchDataRepositoryCollections,
    onCreate,
}) => {
    const base = useBasePath();
    const browserHistory = useHistory();
    const [openModal, setOpenModal] = useState<"COLLECTION" | "REPOSITORY" | "PICK" | null>(null);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenModal("PICK");
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenModal(null);
    };

    const handleCreatedRepository = (dataRepository: DataRepository) => {
        onCreate(dataRepository.id);
        browserHistory.push(`${base}${dataRepository.id}/data-preview/`);
        setOpenModal(null);
        refetchDataRepositories();
        refetchDataRepositoryCollections();
    };

    const handleCreatedRepositoryCollection = () => {
        refetchDataRepositories();
        refetchDataRepositoryCollections();
        setOpenModal(null);
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <PlusLineRound />
            </IconButton>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={openModal !== null}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuItem onClick={() => setOpenModal("REPOSITORY")}>
                    <Stack direction="row" gap={1} alignItems="center">
                        <PlusLineRound />
                        <Typography> {fm(messages.createRepository)}</Typography>
                    </Stack>
                </MenuItem>
                <MenuItem onClick={() => setOpenModal("COLLECTION")}>
                    <Stack direction="row" gap={1} alignItems="center">
                        <FolderLineRound />
                        <Typography> {fm(messages.collection)}</Typography>
                    </Stack>
                </MenuItem>
            </Menu>

            <Dialog
                open={openModal !== null && openModal !== "PICK"}
                onClose={() => {
                    setOpenModal(null);
                }}
            >
                <DialogContent>
                    {openModal === "COLLECTION" && (
                        <>
                            <ExitRow
                                message={fm(messages.createRepositoryCollection)}
                                closer={() => setOpenModal(null)}
                            />
                            <CreateDataRepositoryCollectionModal
                                repositories={dataRepositories}
                                onCreate={handleCreatedRepositoryCollection}
                            />
                        </>
                    )}
                    {openModal === "REPOSITORY" && (
                        <>
                            <ExitRow message={fm(messages.createRepository)} closer={() => setOpenModal(null)} />
                            <CreateDataRepositoryForm
                                dataRepositoryCollections={dataRepositoryCollections}
                                onCreate={handleCreatedRepository}
                            />
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CreateMenu;
