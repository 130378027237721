import { fm } from "@/contexts/IntlContext";
import dayjs, { ManipulateType } from "dayjs";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/react";
import { DataRepository, useCreateRepositoryRowDeleteQueryMutation } from "@/generated/client";
import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, Stack, Typography, Button, FormHelperText } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import messages from "./messages";

export interface RelativeDateForm {
    startDate: Date;
    endDate: Date;
    dateFormat: string;
    dateField: string;
}
interface Props {
    currentRepository: DataRepository;
    onFinish: () => void;
}
const commonFormats = ["YYYY-MM-DD", "YYYY-DD-MM", "DD/MM/YYYY", "MM/DD/YYYY", "YYYY-MM-DDTHH:mm:ss.sssZ"];

const GRANULARITY: ManipulateType[] = ["day", "week", "month", "year"];

export const isGranularity = (value: string | undefined): value is ManipulateType =>
    GRANULARITY.includes(value as ManipulateType);

interface FormType {
    periodGranularity: ManipulateType;
    numberOfPeriods: number;
    dataRepositoryFieldId: string;
    dateFormat: string;
}

const getPreviewString = (values: { periodGranularity?: string; numberOfPeriods?: number }) => {
    const { periodGranularity, numberOfPeriods } = values;
    const dateString =
        isGranularity(periodGranularity) && numberOfPeriods
            ? dayjs()
                  .subtract(numberOfPeriods - 1, periodGranularity)
                  .startOf(periodGranularity)
                  .toString()
            : undefined;
    const fullDateString = `${fm(messages.deleteFromDate)} ${dateString ?? fm(messages.notSelected)}`;
    return fullDateString;
};

export const RelativeDateForm = ({ currentRepository, onFinish }: Props) => {
    const [createAction] = useCreateRepositoryRowDeleteQueryMutation();
    const [previewState, setPreviewState] = useState<{ periodGranularity?: string; numberOfPeriods?: number }>({
        periodGranularity: undefined,
        numberOfPeriods: undefined,
    });

    const [loading, setLoading] = useState(false);

    const schema = z.object({
        periodGranularity: z.enum(["day", "week", "month", "year"]),
        numberOfPeriods: z.coerce.number(),
        dataRepositoryFieldId: z.string(),
        dateFormat: z.string(),
    });

    const { control, handleSubmit } = useForm<FormType>({
        resolver: zodResolver(schema),
    });

    const onSubmit: SubmitHandler<FormType> = (values) => {
        const toUpperMap: { [key: string]: "DAY" | "WEEK" | "MONTH" | "YEAR" } = {
            day: "DAY",
            week: "WEEK",
            month: "MONTH",
            year: "YEAR",
        };
        setLoading(true);
        createAction({
            input: {
                ...values,
                periodGranularity: toUpperMap[values.periodGranularity],
                dataRepositoryId: currentRepository.id,
                queryType: "RELATIVE_DATE",
            },
        })
            .then(() => {
                setLoading(false);
                onFinish();
            })
            .catch((e) => {
                Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
            });
    };

    return (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={1}>
                <Controller
                    control={control}
                    name="dataRepositoryFieldId"
                    render={(fieldValue) => {
                        const { field, fieldState } = fieldValue;
                        return (
                            <FormControl>
                                <InputLabel id="dataRepositoryFieldId-label">{fm(messages.dateField)}</InputLabel>
                                <Select
                                    label={fm(messages.dateField)}
                                    {...field}
                                    error={!!fieldState.error}
                                    labelId="dataRepositoryFieldId-label"
                                >
                                    {currentRepository.fields.map((innerFields) => (
                                        <MenuItem key={innerFields.id} value={innerFields.id}>
                                            {innerFields.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && (
                                    <FormHelperText color="error">
                                        <Typography color="red">{fieldState.error?.message}</Typography>{" "}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        );
                    }}
                />

                <Controller
                    control={control}
                    name="dateFormat"
                    render={(fieldValue) => {
                        const { field, fieldState } = fieldValue;
                        return (
                            <FormControl>
                                <InputLabel id="dateFormat-label">{fm(messages.dateFormat)}</InputLabel>
                                <Select
                                    label={fm(messages.dateFormat)}
                                    {...field}
                                    error={!!fieldState.error}
                                    labelId="dateFormat-label"
                                >
                                    {commonFormats.map((format) => (
                                        <MenuItem key={format} value={format}>
                                            {format}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && (
                                    <FormHelperText color="error">
                                        <Typography color="red">{fieldState.error?.message}</Typography>{" "}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        );
                    }}
                />
                <Stack direction="row" gap={1}>
                    <Controller
                        control={control}
                        name="numberOfPeriods"
                        render={(fieldValue) => {
                            const { field, fieldState } = fieldValue;
                            return (
                                <FormControl fullWidth>
                                    <InputLabel id="numberOfPeriods-label">{fm(messages.numberOfPeriods)}</InputLabel>
                                    <Select
                                        {...field}
                                        error={!!fieldState.error}
                                        label={fm(messages.numberOfPeriods)}
                                        labelId="numberOfPeriods-label"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setPreviewState({
                                                ...previewState,
                                                numberOfPeriods:
                                                    typeof e.target.value === "number"
                                                        ? e.target.value
                                                        : Number.parseInt(e.target.value, 10),
                                            });
                                        }}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
                                            <MenuItem key={number} value={number}>
                                                {number}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {fieldState.error && (
                                        <FormHelperText color="error">
                                            <Typography color="red">{fieldState.error?.message}</Typography>{" "}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            );
                        }}
                    />

                    <Controller
                        control={control}
                        name="periodGranularity"
                        render={(fieldValue) => {
                            const { field, fieldState } = fieldValue;
                            return (
                                <FormControl fullWidth>
                                    <InputLabel id="periodGranularity-label">{fm(messages.period)}</InputLabel>
                                    <Select
                                        {...field}
                                        error={!!fieldState.error}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setPreviewState({ ...previewState, periodGranularity: e.target.value });
                                        }}
                                        label={fm(messages.period)}
                                        labelId="periodGranularity-label"
                                    >
                                        {GRANULARITY.map((granularity) => (
                                            <MenuItem key={granularity.toString()} value={granularity.toString()}>
                                                {granularity.toString()}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {fieldState.error && (
                                        <FormHelperText color="error">
                                            <Typography color="red">{fieldState.error?.message}</Typography>{" "}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            );
                        }}
                    />
                </Stack>
            </Stack>
            <Typography>{getPreviewString(previewState)}</Typography>
            <Stack direction="row" justifyContent="flex-end" gap={1}>
                <Button onClick={onFinish} variant="outlined" color="secondary">
                    {fm(messages.cancel)}
                </Button>
                <LoadingButton variant="contained" type="submit" color="success" loading={loading}>
                    {fm(messages.saveQuery)}
                </LoadingButton>
            </Stack>
        </form>
    );
};
