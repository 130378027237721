import React from "react";
import { Root, createRoot } from "react-dom/client";
import { setupProxyAuth } from "./setupProxyAuth";
import App from "./App";

async function main() {
    let root: Root;
    async function devSetup() {
        const isProxyToProductionBackend = process.env.REACT_APP_PROXY_WEB_TO_PRODUCTION === "true";

        if (isProxyToProductionBackend) {
            await setupProxyAuth();
        }
        const node = document.getElementById("dev-root");
        if (node) {
            root = createRoot(node);
            root.render(<App />);
        }
    }

    if (!document.getElementById("DataRepositories-container")) {
        await devSetup();
    }

    window.renderDataRepositories = (containerId, props) => {
        const container = document.getElementById(containerId);
        root = createRoot(container!);
        root.render(<App history={props.history} locale={props.locale} theme={props.theme} path={props.path} />);
    };

    window.unmountDataRepositories = () => {
        root?.unmount();
    };
}

// eslint-disable-next-line @typescript-eslint/no-floating-promises
main();
