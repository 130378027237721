import { defineMessages } from "react-intl";

const messages = defineMessages({
    configureIntegration: {
        id: "uploadPage.dataRepositoryPage.settingsPage.configureIntegration",
        defaultMessage: "Configure integration",
    },
    pushJson: {
        id: "uploadPage.dataRepositoryPage.settingsPage.pushJson",
        defaultMessage: "Push JSON",
    },
    pushFile: {
        id: "uploadPage.dataRepositoryPage.settingsPage.pushFile",
        defaultMessage: "Push file",
    },
    tokenHeader: {
        id: "uploadPage.dataRepositoryPage.settingsPage.tokenHeader",
        defaultMessage: "Authentication header",
    },
    generateToken: {
        id: "uploadPage.dataRepositoryPage.settingsPage.generateToken",
        defaultMessage: "Generate token",
    },
    displayTokenInfo: {
        id: "uploadPage.dataRepositoryPage.settingsPage.displayTokenInfo",
        defaultMessage: "Token is only displayed immediately after generation.",
    },
    tokenGenerationFailure: {
        id: "uploadPage.dataRepositoryPage.settingsPage.tokenGenerationFailure",
        defaultMessage:
            "Failed to generate integration token. Only administrators are allowed to configure an integration source.",
    },
    contentType: {
        id: "uploadPage.dataRepositoryPage.settingsPage.contentType",
        defaultMessage: "Type of file",
    },
    compressed: {
        id: "uploadPage.dataRepositoryPage.settingsPage.compressed",
        defaultMessage: "Compressed (.gz)?",
    },
    helpTextHeader: {
        id: "uploadPage.dataRepositoryPage.settingsPage.helpTextHeader",
        defaultMessage: "Description",
    },
    helpText: {
        id: "uploadPage.dataRepositoryPage.settingsPage.helpText",
        defaultMessage:
            "Configure your source to receive files by API. Please give us some information about your file.",
    },
});

export default messages;
