import { defineMessages } from "react-intl";

const messages = defineMessages({
    uploading: {
        id: "uploadPage.UploadDataPage.FileUpload.uploading",
        defaultMessage: "Uploading",
    },
    parsing: {
        id: "uploadPage.UploadDataPage.FileUpload.parsing",
        defaultMessage: "Parsing",
    },
    finished: {
        id: "uploadPage.UploadDataPage.FileUpload.finished",
        defaultMessage: "Finished uploading",
    },
    fileUploadError: {
        id: "uploadPage.UploadDataPage.FileUpload.fileUploadError",
        defaultMessage: "Error: Something went wrong.",
    },
    xlsxTipUploadTip: {
        id: "uploadPage.UploadDataPage.FileUpload.xlsxTipUploadTip",
        defaultMessage: "(If you are on a windows computer, make sure the file is not open in Excel)",
    },
});

export default messages;
