import { defineMessages } from "react-intl";

const messages = defineMessages({
    exampleRequest: {
        id: "uploadPage.dataRepositoryPage.settingsPage.exampleRequest",
        defaultMessage: "Example request",
    },
    requestUrl: {
        id: "uploadPage.dataRepositoryPage.settingsPage.requestUrl",
        defaultMessage: "URL",
    },
    fileType: {
        id: "uploadPage.dataRepositoryPage.settingsPage.fileType",
        defaultMessage: "File type",
    },
});

export default messages;
