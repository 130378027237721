import { defineMessages } from "react-intl";

const messages = defineMessages({
    cancel: {
        id: "components.DeletePrompt.cancel",
        defaultMessage: "Cancel",
    },
    delete: {
        id: "components.DeletePrompt.delete",
        defaultMessage: "Delete",
    },
});

export default messages;
