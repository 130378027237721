import { FeatureToggleContextProvider } from "@ignite-analytics/feature-toggle";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { useBasePath } from "../contexts/BasePathContext";
import { DataManagementProcessContextProvider } from "../contexts/DataManagementProcessContext";
import { EntityChangeEventContextProvider } from "../contexts/EntityEventChangeContext";
import NotificationsContextProvider from "../contexts/NotificationsContext";
import { useCurrentUser } from "../entities/users";
import { UniqueIdentifierConfigurationContext } from "../generated/UniqueIdentifierConfigurationEntity";
import DataRepositoryPage from "./DataRepositoryPage";
/**
 * This container is the root container in this micro app.
 * It fetches the data needed for the initial rendering, and then it renders one of the
 * sub containers (determined based on URL).
 */

const ApplicationPage: React.FC = () => {
    const base = useBasePath();

    const user = useCurrentUser();

    if (user) {
        return (
            <FeatureToggleContextProvider userEmail={user.email} tenant={user.tenant}>
                <EntityChangeEventContextProvider userId={user.id} channel={0} topic="entity-change-event">
                    <DataManagementProcessContextProvider userId={user.id} channel={0} topic="datamanagement-events">
                        <NotificationsContextProvider userId={user.id} channel={0} topic="notifier-broadcast">
                            <UniqueIdentifierConfigurationContext>
                                <Switch>
                                    <Route path={[`${base}:repositoryId/`, `*/`]}>
                                        <DataRepositoryPage />
                                    </Route>
                                </Switch>
                            </UniqueIdentifierConfigurationContext>
                        </NotificationsContextProvider>
                    </DataManagementProcessContextProvider>
                </EntityChangeEventContextProvider>
            </FeatureToggleContextProvider>
        );
    }
    return null;
};

export default ApplicationPage;
