import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { useBasePath } from "@/contexts/BasePathContext";
import { fm } from "@/contexts/IntlContext";
import { DataRepository, DataRepositoryCollection } from "@/generated/client";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import globalMessages from "../../messages";
import { RepositoryCollection } from "./components/repositoryCollection";
import messages from "./messages";
import { ExpandButton, TransitionStack } from "./style";

export const SIDE_MENU_WIDTH = 260;

interface Props {
    dataRepositoryCollections: DataRepositoryCollection[];
    dataRepositories: DataRepository[];
    onClick: (repositoryId: string) => void;
    CreateButton?: JSX.Element;
    sideMenuOpen: boolean;
    onMenuToggle: () => void;
}

const DataRepositoryMenu: React.FC<Props> = ({
    dataRepositoryCollections,
    dataRepositories,
    onClick,
    CreateButton,
    sideMenuOpen,
    onMenuToggle,
}) => {
    const base = useBasePath();
    const browserHistory = useHistory();

    const { repositoryId: currentRepositoryId } = useParams<{ repositoryId: string }>();

    const initialRepositoryId = dataRepositories[0]?.id ?? "";
    if (initialRepositoryId && (!currentRepositoryId || !dataRepositories.find((r) => r.id === currentRepositoryId)))
        browserHistory.push(`${base}${initialRepositoryId}/data-preview/`);
    return (
        <TransitionStack
            width={sideMenuOpen ? SIDE_MENU_WIDTH : undefined}
            paddingX={sideMenuOpen ? 2 : 0}
            paddingY={2}
        >
            <Stack direction="row" justifyContent="flex-end">
                <ExpandButton onClick={onMenuToggle} sx={{ right: sideMenuOpen ? "-27px" : "-10px" }}>
                    {sideMenuOpen && <ChevronLeft sx={{ marginRight: "1px" }} />}
                    {!sideMenuOpen && <ChevronRight sx={{ marginLeft: "1px" }} />}
                </ExpandButton>
            </Stack>
            {sideMenuOpen && (
                <Stack sx={{ marginTop: "-32px" }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">{fm(messages.dataSources)}</Typography>
                        {CreateButton}
                    </Stack>
                    <Stack sx={{ maxHeight: `calc(100vh - 120px)`, overflow: "auto" }}>
                        {dataRepositoryCollections.map((collection) => {
                            return (
                                <RepositoryCollection
                                    key={collection.id}
                                    repositories={dataRepositories}
                                    collection={collection}
                                    currentRepositoryId={currentRepositoryId || initialRepositoryId}
                                    onClick={onClick}
                                />
                            );
                        })}
                        <RepositoryCollection
                            repositories={dataRepositories}
                            collection={{ name: fm(globalMessages.other).toString() }}
                            currentRepositoryId={currentRepositoryId || initialRepositoryId}
                            onClick={onClick}
                        />
                    </Stack>
                </Stack>
            )}
        </TransitionStack>
    );
};

export default DataRepositoryMenu;
