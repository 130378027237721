import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Stack, Tooltip } from "@mui/material";
import React from "react";

interface Props {
    handleBack: () => void;
    backButtonMessage: string;
    handleNext: () => void;
    nextButtonMessage: string;
    nextButtonDisabled?: boolean;
    loading: boolean;
    nextButtonTooltip?: string;
    dataTestId?: string;
}

export const BottomButtonRow: React.FC<Props> = ({
    handleBack,
    backButtonMessage,
    handleNext,
    nextButtonDisabled,
    nextButtonMessage,
    loading,
    nextButtonTooltip,
    dataTestId,
}) => {
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
            <Button size="small" color="secondary" sx={{ minWidth: 70 }} onClick={handleBack}>
                {backButtonMessage}
            </Button>
            <Tooltip title={nextButtonTooltip || ""} placement="top">
                <div>
                    <LoadingButton
                        variant="outlined"
                        size="small"
                        color="primary"
                        sx={{ minWidth: "120px" }}
                        disabled={!!nextButtonDisabled}
                        loading={loading}
                        onClick={handleNext}
                        data-testid={dataTestId}
                    >
                        {nextButtonMessage}
                    </LoadingButton>
                </div>
            </Tooltip>
        </Stack>
    );
};
