import { defineMessages } from "react-intl";

const messages = defineMessages({
    noData: {
        id: "uploadPage.dataRepositoryTable.noData",
        defaultMessage: "No data in this source",
    },
    serachFields: {
        id: "uploadPage.dataRepositoryTable.serachFields",
        defaultMessage: "Search data fields",
    },
    search: {
        id: "uploadPage.dataRepositoryTable.search",
        defaultMessage: "Search",
    },
});

export default messages;
