import { defineMessages } from "react-intl";

const messages = defineMessages({
    fileFieldName: {
        id: "uploadPage.importConfiguration.importFieldMapping.fileFieldName",
        defaultMessage: "File field name",
    },
    repositoryFieldName: {
        id: "uploadPage.importConfiguration.importFieldMapping.repositoryFieldName",
        defaultMessage: "Source field name",
    },
    examples: {
        id: "upload.importConfiguration.importFieldMapping.examples",
        defaultMessage: "Examples",
    },
    mappingStatus: {
        id: "upload.importConfiguration.importFieldMapping.mappingStatus",
        defaultMessage: "Mapping status",
    },
    includedFields: {
        id: "upload.importConfiguration.importFieldMapping.includedFields",
        defaultMessage: "Included fields",
    },
    submitButton: {
        id: "upload.importConfiguration.importFieldMapping.includedFields",
        defaultMessage: "Create import mapping",
    },
    search: {
        id: "upload.importConfiguration.importFieldMapping.searchDataColumns",
        defaultMessage: "Search data columns",
    },
    missingRequiredFields: {
        id: "upload.importConfiguration.importFieldMapping.missingRequiredFields",
        defaultMessage: "Missing required fields: {missingFields}",
    },
});

export default messages;
