import { Stringish } from "@/utils";
import { Cancel, Delete } from "@mui/icons-material";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { fm } from "@/contexts/IntlContext";
import React from "react";
import messages from "./messages";

interface Props {
    // eslint-disable-next-line react-redux/no-unused-prop-types, react/no-unused-prop-types
    children?: React.ReactNode;
    description?: Stringish;
    acceptText?: string;
    declineText?: string;
    isProcessing?: boolean;
    acceptTestId?: string;
    declineTestId?: string;
    open: boolean;
    header?: Stringish;

    // Callbacks
    onAccept: () => void;
    onClose: () => void;
}

/**
 * Simple prompt that can be used to present the user with a
 * chance to abort a request
 */

const Prompt: React.FC<Props> = ({
    description = fm(messages.defaultDescription),
    acceptText = fm(messages.acceptButton),
    declineText = fm(messages.declineButton),
    onAccept,
    onClose,
    open,
    header,
    isProcessing = false,

    acceptTestId,
    declineTestId,
}) => {
    return (
        <Dialog open={open}>
            <DialogTitle>{header?.toString()}</DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    <Typography>{description.toString()}</Typography>
                    {isProcessing ? (
                        <Stack direction="row" alignItems="center" padding={{ vertical: "m" }}>
                            <CircularProgress />
                        </Stack>
                    ) : (
                        <Stack direction="row" justifyContent="space-between" padding={{ top: "m" }}>
                            <Button
                                data-testid={declineTestId || "prompt-cancelButton"}
                                onClick={onClose}
                                color="secondary"
                                size="small"
                                variant="outlined"
                                endIcon={<Cancel />}
                            >
                                {declineText}
                            </Button>
                            <Button
                                data-testid={acceptTestId || "prompt-confirmButton"}
                                onClick={onAccept}
                                variant="outlined"
                                color="error"
                                size="small"
                                endIcon={<Delete />}
                            >
                                {acceptText}
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default Prompt;
