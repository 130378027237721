import { defineMessages } from "react-intl";

const messages = defineMessages({
    formNameInputLabel: {
        id: "uploadPage.createImportConfigurationForm.formNameInputLabel",
        defaultMessage: "Name",
    },
    formImportTypeInputLabel: {
        id: "uploadPage.createImportConfigurationForm.formImportTypeInputLabel",
        defaultMessage: "CSV or XLSX",
    },
    header: {
        id: "uploadPage.createImportConfigurationForm.header",
        defaultMessage: "Advanced options",
    },
    delimiter: {
        id: "uploadPage.createImportConfigurationForm.delimiter",
        defaultMessage: "Delimiter",
    },
    quotechar: {
        id: "uploadPage.createImportConfigurationForm.quotechar",
        defaultMessage: "Quote character",
    },
    escapechar: {
        id: "uploadPage.createImportConfigurationForm.escapechar",
        defaultMessage: "Escape character",
    },
    encoding: {
        id: "uploadPage.createImportConfigurationForm.encoding",
        defaultMessage: "Encoding",
    },
    headerRowIndex: {
        id: "uploadPage.createImportConfigurationForm.headerRowIndex",
        defaultMessage: "Header line",
    },
    compression: {
        id: "uploadPage.createImportConfigurationForm.compression",
        defaultMessage: "Compression",
    },
    none: {
        id: "uploadPage.createImportConfigurationForm.none",
        defaultMessage: "None",
    },
    dataStartIndex: {
        id: "uploadPage.createImportConfigurationForm.dataStartIndex",
        defaultMessage: "Include lines from row",
    },
    numberOfEndLinesToDrop: {
        id: "uploadPage.createImportConfigurationForm.numberOfEndLinesToDrop",
        defaultMessage: "Drop {n} bottom rows",
    },
    sheetMessage: {
        id: "uploadPage.createImportConfigurationForm.sheetMessage",
        defaultMessage: "Note: Ignite will only read the first sheet of your excel file.",
    },
});

export default messages;
