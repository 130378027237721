import { defineMessages } from "react-intl";

const messages = defineMessages({
    nameRepository: {
        id: "uploadPage.onboardingPage.nameRepository",
        defaultMessage: "Name your data source",
    },
    repositoryExplanation: {
        id: "uploadPage.onboardingPage.repositoryExplanation",
        defaultMessage: "A data source is where Ignite stores your raw data",
    },
    dataTables: {
        id: "uploadPage.onboardingPage.dataTables",
        defaultMessage: "Data Tables",
    },
    dataTableExplanation: {
        id: "uploadPage.onboardingPage.dataTableExplanation",
        defaultMessage:
            " Next step is to Create a Data Table. In Ignite you can clean, transform, enrich your raw data from data sources into Data Tables. This unlocks your data's potential for anlysis and insight.",
    },
    verify: {
        id: "uploadPage.onboardingPage.verify",
        defaultMessage: "Verify",
    },
    verifyText: {
        id: "uploadPage.onboardingPage.verifyText",
        defaultMessage: "Verify that we parsed the file correctly and continue",
    },
    pipelineExplanation: {
        id: "uploadPage.onboardingPage.pipelineExplanation",
        defaultMessage:
            "In Ignite you can design custom data flows and perform operations on your raw data before it is inserted to your data tables. We just created an empty flow for you that you can check out, change and verify by clicking the button below.",
    },
    dataFlow: {
        id: "uploadPage.onboardingPage.dataFLow",
        defaultMessage: "Data Flow",
    },
    toPipelineButton: {
        id: "uploadPage.onboardingPage.toPipelineButton",
        defaultMessage: "Take me to data flow",
    },
    file: {
        id: "uploadPage.onboardingPage.file",
        defaultMessage: "File",
    },
    fileStep: {
        id: "uploadPage.onboardingPage.fileSTep",
        defaultMessage: "Choose the file you want to upload and tell us a little bit about the format.",
    },
    upload: {
        id: "uploadPage.onboardingPage.upload",
        defaultMessage: "Upload",
    },
    uploadText: {
        id: "uploadPage.onboardingPage.uploadText",
        defaultMessage:
            "Your data is being uploaded. Follow the progress by clicking the bell icon in the upper right corner. Feel free to continue with the following steps to set up your data flow.",
    },
    createSupplierHeader: {
        id: "uploadPage.onboardingPage.createSupplierHeader",
        defaultMessage: "Create Suppliers",
    },
    skip: {
        id: "uploadPage.onboardingPage.skip",
        defaultMessage: "Skip this step",
    },
    supplierFields: {
        id: "uploadPage.onboardingPage.supplierFields",
        defaultMessage:
            "1) To Generate suppliers we need to know which fields from your transactions table contains supplier information",
    },
    supplierEnrichmentFields: {
        id: "uploadPage.onboardingPage.supplierFields",
        defaultMessage:
            "3) Please input your columns for Supplier Org Number, Name and Country to enable financial data enrichment.",
    },
    uniqueSupplierFields: {
        id: "uploadPage.onboardingPage.uniqueSupplierFields",
        defaultMessage:
            "2) Please select fields that together uniquely identify a supplier. Usually registration number or a combination of supplier name and country is sufficient",
    },
    supplierFieldsLabel: {
        id: "uploadPage.onboardingPage.supplierFieldsLabel",
        defaultMessage: "Select supplier columns",
    },
    uniqueSupplierFieldsLabel: {
        id: "uploadPage.onboardingPage.uniqueSupplierFIelds",
        defaultMessage: "Select unique identifier column(s)",
    },
    noSourcesPage: {
        id: "uploadPage.onboardingPage.noSourcesPage",
        defaultMessage: "No data sources",
    },
    startOnboarding: {
        id: "uploadPage.onboardingPage.startOnboarding",
        defaultMessage: "Start Onboarding",
    },
    newAnalysis: {
        id: "uploadPage.onboardingPage.newAnalysis",
        defaultMessage: "Take me to new Analysis",
    },
    dataCube: {
        id: "uploadPage.onboardingPage.dataCube",
        defaultMessage: "Take me to Data Cube",
    },
    uploading: {
        id: "uploadPage.onboardingPage.uploading",
        defaultMessage: "Uploading",
    },
    parsing: {
        id: "uploadPage.onboardingPage.parsing",
        defaultMessage: "Parsing",
    },
    finished: {
        id: "uploadPage.onboardingPage.finished",
        defaultMessage: "Finished uploading",
    },
});

export default messages;
