import { Grid, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";

export const RenderCell = (props: GridRenderCellParams) => {
    const { tabIndex, formattedValue, colDef } = props;
    return (
        <div tabIndex={tabIndex}>
            <Tooltip title={formattedValue} placement="bottom-start">
                <Grid maxWidth={(colDef.width ?? 100) - 5} textOverflow="ellipsis">
                    {/* no wrap is needed to med text overflow work  reason is explained here https://mui.com/material-ui/react-menu/#limitations */}
                    <Typography variant="body2" noWrap>
                        {formattedValue}
                    </Typography>
                </Grid>
            </Tooltip>
        </div>
    );
};
