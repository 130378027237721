import { getContentTypeFromFile } from "@/components/ImportConfiguration/CreateImportConfiguationForm/helpers";
import { CreateImportConfigurationInput, DataRepository } from "@/generated/client";

export function getCreateInput(dataRepository: DataRepository, file: File): CreateImportConfigurationInput {
    return {
        targetDataRepository: dataRepository.id,
        importType: "FILE",
        compressionType: null,
        name: "MANUAL_UPLOAD",
        contentType: getContentTypeFromFile(file),
        csvConfiguration: {
            delimiter: "COMMA",
            encoding: "UTF",
            escapeCharacter: "DOUBLE_QUOTE",
            quoteCharacter: "DOUBLE_QUOTE_QUOTING",
        },
        xlsxConfiguration: {
            headerRowIndex: 1,
            dataStartIndex: 2,
            numberOfEndLinesToDrop: 0,
        },
    };
}
