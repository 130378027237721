import { NotificationsList } from "@ignite-analytics/notifications";
import { Typography, Stack, Grid } from "@mui/material";
import { fm } from "@/contexts/IntlContext";
import React from "react";
import messages from "../../messages";

export const ReviewStep = () => {
    return (
        <Stack gap={1}>
            <Typography variant="subtitle1">{fm(messages.uploadReview)}</Typography>
            <Grid container overflow="scroll" maxHeight="25vh" minHeight="25vh">
                <NotificationsList />
            </Grid>
        </Stack>
    );
};
