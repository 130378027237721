import { formatValue } from "@ignite-analytics/locale";
import { Avatar, Checkbox, CircularProgress, Link, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { fm } from "@/contexts/IntlContext";
import dayjs from "dayjs";
import React from "react";
import { getUploadInfo } from "../helpers";
import messages from "./messages";

interface Props {
    importObject: {
        id: string;
        file: {
            name: string;
            downloadUrl?: string | null;
        };
        importedRows: number;
        updatedRows: number;
        ignoredEmpty: number;
        ignoredMissing: number;
        alreadyExisting: number;
        importedBy:
            | {
                  firstName: string;
                  lastName: string;
              }
            | undefined;
        isBeingDeleted: boolean;
        importedAt: string;
    };
    checked: boolean;
    onCheckCheckBox: (deleteId: string, remove: boolean) => void;
}

const ImportLogTableRow: React.FC<Props> = ({ importObject, onCheckCheckBox, checked }) => {
    const uploadInfo = getUploadInfo(
        importObject.importedRows,
        importObject.updatedRows,
        importObject.ignoredEmpty,
        importObject.ignoredMissing,
        importObject.alreadyExisting
    );
    const formatImportNumber = (value: number | string) => {
        return typeof value === "number" ? formatValue(value, 0) : value;
    };
    return (
        <TableRow>
            <TableCell>
                {importObject.isBeingDeleted ? (
                    <CircularProgress size={25} color="primary" />
                ) : (
                    <Checkbox
                        size="small"
                        style={{ padding: 0 }}
                        name="shouldDelete"
                        checked={checked}
                        onChange={() => onCheckCheckBox(importObject.id, checked)}
                    />
                )}
            </TableCell>
            <TableCell>
                {importObject.file?.downloadUrl ? (
                    <Link href={importObject.file.downloadUrl} textOverflow="ellipsis" variant="body2">
                        {importObject.file.name}
                    </Link>
                ) : (
                    <Typography textOverflow="ellipsis" variant="body2">
                        {importObject.file.name}
                    </Typography>
                )}
            </TableCell>
            <TableCell>
                <Tooltip title={dayjs(importObject.importedAt).format("YYYY-MM-DD HH:mm")}>
                    <Typography textOverflow="ellipsis" variant="body2">
                        {dayjs(importObject.importedAt).format("YYYY-MM-DD")}
                    </Typography>
                </Tooltip>
            </TableCell>
            <TableCell>
                {importObject.importedBy ? (
                    <Tooltip title={`${importObject.importedBy.firstName} ${importObject.importedBy.lastName}`}>
                        <Avatar sx={{ width: 30, height: 30 }}>
                            <Typography>
                                {importObject.importedBy.firstName ? importObject.importedBy.firstName[0] : ""}
                                {importObject.importedBy.lastName ? importObject.importedBy.lastName[0] : ""}
                            </Typography>
                        </Avatar>
                    </Tooltip>
                ) : (
                    <Typography variant="body2">{fm(messages.integration)}</Typography>
                )}
            </TableCell>
            <TableCell>
                <Typography variant="body2">{formatImportNumber(uploadInfo.imported)}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2">{formatImportNumber(uploadInfo.updated)}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2">{formatImportNumber(uploadInfo.invalid)}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2">{formatImportNumber(uploadInfo.ignored)}</Typography>
            </TableCell>
            <TableCell sx={{ maxWidth: "145px" }}>
                <Tooltip title={fm(messages.importIdDescription)} placement="right">
                    <Typography
                        noWrap
                        sx={{ cursor: "copy" }}
                        variant="body2"
                        onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                            navigator.clipboard.writeText(importObject.id);
                        }}
                    >
                        {importObject.id}
                    </Typography>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default ImportLogTableRow;
