import { defineMessages } from "react-intl";

const messages = defineMessages({
    prompt: {
        id: "fileDropZone.prompt",
        defaultMessage: "Drop your file here",
    },
    browsePrompt: {
        id: "fileDropZone.prompt2",
        defaultMessage: "Or click to browse local files",
    },
});

export default messages;
