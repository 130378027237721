import { defineMessages } from "react-intl";

const messages = defineMessages({
    tooltipSamples: {
        id: "uploadPage.importConfiguration.repositoryRow.tooltipSamples",
        defaultMessage: "No more unique samples to show",
    },
    newField: {
        id: "uploadPage.importConfiguration.repositoryRow.newField",
        defaultMessage: "New Field - {name}",
    },
    newFieldOption: {
        id: "uploadPage.importConfiguration.repositoryRow.newFieldOption",
        defaultMessage: "NEW FIELD",
    },
    selectMapping: {
        id: "uploadPage.importConfiguration.repositoryRow.selectMapping",
        defaultMessage: "Select source field",
    },
    duplicateError: {
        id: "uploadPage.importConfiguration.repositoryRow.duplicateError",
        defaultMessage: "Duplicated column names will be ignored",
    },
    existingError: {
        id: "uploadPage.importConfiguration.repositoryRow.existingError",
        defaultMessage: "Source field with this name already exists, so will be ignored",
    },
    error: {
        id: "uploadPage.importConfiguration.repositoryRow.error",
        defaultMessage: "Error",
    },
    new: {
        id: "uploadPage.importConfiguration.repositoryRow.new",
        defaultMessage: "New",
    },
    ignored: {
        id: "uploadPage.importConfiguration.repositoryRow.ignored",
        defaultMessage: "Ignored",
    },
    mapped: {
        id: "uploadPage.importConfiguration.repositoryRow.mapped",
        defaultMessage: "Mapped",
    },
});

export default messages;
