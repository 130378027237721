import { defineMessages } from "react-intl";

const messages = defineMessages({
    fieldCount: {
        id: "uploadPage.dataRepositoryPage.dataPreview.stats.fieldCount",
        defaultMessage: "{fieldCount} Fields",
    },
    rowCount: {
        id: "uploadPage.dataRepositoryPage.dataPreview.stats.rowCount",
        defaultMessage: "{rowCount} Rows",
    },
    rows: {
        id: "uploadPage.dataRepositoryPage.dataPreview.stats.rows",
        defaultMessage: "Rows",
    },
    loading: {
        id: "uploadPage.dataRepositoryPage.dataPreview.stats.loading",
        defaultMessage: "Loading number of rows",
    },
});

export default messages;
