import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { PropsWithChildren } from "react";
import { useHistory } from "react-router-dom";

export interface TabLink {
    path: string;
    name: string;
    active: boolean;
    dataTestId: string;
}

type Props = PropsWithChildren<{
    tabLinks: TabLink[];
    sideMenuOpen: boolean;
    AddDataButton?: JSX.Element;
}>;
const ContentCard: React.FC<Props> = ({ children, tabLinks, sideMenuOpen, AddDataButton }) => {
    const history = useHistory();

    const handleTabsClick = (path: string) => {
        history.push(path);
    };
    return (
        <Card
            sx={{
                padding: 3,
                minHeight: "calc(100vh - 49px)",
                maxWidth: sideMenuOpen ? "calc(100vw - 330px)" : "calc(100vw - 90px)",
            }}
        >
            <Stack gap={1}>
                <Stack direction="row" justifyContent="space-between">
                    <Tabs
                        value={tabLinks.find((link) => link.active)?.path}
                        onChange={(_e, v) => handleTabsClick(String(v))}
                        aria-label="wrapped label tabs example"
                    >
                        {tabLinks.map((link) => {
                            return <Tab key={link.name} value={link.path} label={link.name} wrapped />;
                        })}
                    </Tabs>
                    {AddDataButton}
                </Stack>
                {children}
            </Stack>
        </Card>
    );
};

export default ContentCard;
