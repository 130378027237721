import { track } from "@ignite-analytics/track";
import * as Sentry from "@sentry/react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Alert,
    Autocomplete,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormHelperText,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { fm } from "@/contexts/IntlContext";
import {
    DataRepository,
    DataRepositoryField,
    UniqueIdentifierConfiguration,
    useCreateUniqueIdentifierConfigurationMutation,
    useDeleteUniqueIdentifierConfigurationMutation,
    useUpdateUniqueIdentifierConfigurationMutation,
} from "@/generated/client";
import React, { useEffect, useState } from "react";
import messages from "./messages";

export type MutationName = "create" | "update" | "delete";

interface Props {
    repository: DataRepository;
    currentConfig: UniqueIdentifierConfiguration | undefined | null;
    onMutation: (mut: MutationName) => void;
    onCancel: () => void;
}

export const CreateUniqueIdentifierConfigurationForm: React.FC<Props> = ({
    repository,
    currentConfig,
    onMutation,
    onCancel,
}) => {
    const [createUniqueIdentifierConfiguration, { result: createResult }] =
        useCreateUniqueIdentifierConfigurationMutation();
    const [updateUniqueIdentifierConfiguration, { result: updateResult }] =
        useUpdateUniqueIdentifierConfigurationMutation();
    const [deleteUniqueConfigurationMutation, { result: deleteResult }] =
        useDeleteUniqueIdentifierConfigurationMutation();

    const [hasChecked, setHasChecked] = useState(false);
    const [formState, setFormState] = useState<DataRepositoryField[]>(
        repository.fields.filter((field) =>
            repository.uniqueIdentifierConfiguration?.uniqueIdentifierFields.some(
                (uniqueField) => field.id === uniqueField
            )
        ) || []
    );

    const changes = !(
        JSON.stringify(formState.map((f) => f.id)) === JSON.stringify(currentConfig?.uniqueIdentifierFields)
    );

    let currentMutation: MutationName;
    if (!currentConfig) {
        currentMutation = "create";
    } else if (formState.length === 0) {
        currentMutation = "delete";
    } else {
        currentMutation = "update";
    }

    useEffect(
        function unCheckConfirmationOnMutationChange() {
            setHasChecked(false);
        },
        [currentMutation]
    );

    const handleClick = (uniqueIdentifierFields: string[]) => {
        if (!hasChecked) return;
        let mutationPromise: Promise<unknown>;
        switch (currentMutation) {
            case "create":
                if (!uniqueIdentifierFields.length) return;
                mutationPromise = createUniqueIdentifierConfiguration({
                    input: {
                        uniqueIdentifierFields,
                        retroactive: true,
                        partialReplace: false,
                        repositoryId: repository.id,
                    },
                });

                track("Repository settings: Create Unique Identifier Configuration");
                break;
            case "update":
                if (!repository.uniqueIdentifierConfiguration) return;
                if (!uniqueIdentifierFields.length) return;
                mutationPromise = updateUniqueIdentifierConfiguration({
                    input: {
                        update: {
                            uniqueIdentifierFields,
                        },
                        id: repository.uniqueIdentifierConfiguration.id,
                        mask: ["repositoryId"],
                    },
                });
                track("Repository settings: Update Unique Identifier Configuration");
                break;
            case "delete":
                if (!repository.uniqueIdentifierConfiguration) return;
                mutationPromise = deleteUniqueConfigurationMutation({
                    input: { id: repository.uniqueIdentifierConfiguration.id },
                });
                track("Repository settings: Delete Unique Identifier Configuration");
                break;
        }

        // Refetch, show notification, and close modal on mutation success
        if (mutationPromise !== undefined) {
            mutationPromise
                .then(() => {
                    onMutation(currentMutation);
                    onCancel();
                })
                .catch((e) => {
                    Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
                });
        }
    };

    return (
        <Stack sx={{ marginTop: 1 }}>
            <Autocomplete
                multiple
                id="tags-standard"
                options={repository.fields}
                getOptionLabel={(option) => option.name}
                onChange={(_event, v) => setFormState(v)}
                value={[...formState]}
                renderInput={(params) => (
                    <TextField {...params} label={fm(messages.select)} helperText={fm(messages.helpText)} />
                )}
            />
            <Card sx={{ cursor: "pointer" }} onClick={() => setHasChecked((prev) => !prev)}>
                <CardContent>
                    <Typography>{fm(messages.checkbuttonTitle)}</Typography>
                    <Stack direction="row" alignItems="center">
                        <Checkbox checked={hasChecked} onChange={(e) => setHasChecked(e.target.checked)} />
                        <FormHelperText>
                            {fm(
                                currentMutation === "delete"
                                    ? messages.checkbuttonTextDelete
                                    : messages.checkbuttonTextCreate
                            )}
                        </FormHelperText>
                    </Stack>
                </CardContent>
            </Card>
            <Stack direction="row" justifyContent="right" gap={2}>
                <Button variant="text" onClick={onCancel}>
                    {fm(messages.cancel)}
                </Button>

                <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={!hasChecked || !changes || (currentMutation === "create" && !formState.length)}
                    onClick={() => handleClick(formState.map((f) => f.id))}
                    loading={
                        updateResult.type === "loading" ||
                        createResult.type === "loading" ||
                        deleteResult.type === "loading"
                    }
                >
                    {fm(messages.confirm)}
                </LoadingButton>
            </Stack>
            {[updateResult, createResult, deleteResult].map((result, i) => {
                if (result.type !== "error") return null;
                return (
                    // This is a constant length array, so the index is a perfectly fine key
                    // eslint-disable-next-line react/no-array-index-key
                    <Alert key={i} title={result.error.message} severity="error">
                        {result.error.message}
                    </Alert>
                );
            })}
        </Stack>
    );
};
