import { HeartBroken } from "@mui/icons-material";
import { Alert, AlertTitle, Button, Stack, Typography } from "@mui/material";
import { fm } from "@/contexts/IntlContext";
import React from "react";
import messages from "./messages";

const FatalErrorFallback: React.FC = () => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "80vh" }}>
            <Alert
                severity="error"
                sx={{ textAlign: "center", alignItems: "center", justifyContent: "center", width: "60%" }}
                icon={false}
            >
                <Stack direction="column" alignItems="center" justifyContent="center" gap={2}>
                    <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
                        <HeartBroken fontSize="large" sx={{ marginBottom: "10px" }} />
                        <AlertTitle sx={{ fontSize: 38 }}>{fm(messages.error)}</AlertTitle>
                    </Stack>
                    <Typography sx={{ fontSize: 22 }}>{fm(messages.somethingWentWrong)}</Typography>
                    <Button size="large" onClick={() => window.location.reload()}>
                        {fm(messages.refresh)}
                    </Button>
                </Stack>
            </Alert>
        </Stack>
    );
};

export default FatalErrorFallback;
